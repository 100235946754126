export const ENV = '.com';
const config = {

   APP_URL:'https://business.globalgarner.com/',
   VENDOR_YOUTUBE_VIDEO:'vG_Hp7ddxK0',
   USER_BENEFITS_VIDEO:'_07Z8Wo9ZDw',
   UPOS_YOUTUBE_VIDEO:'XebvCkBcnCw',
   FRANCHISE_YOUTUBE_VIDEO:'rQf9vKEv8X0',
   PARTNER_YOUTUBE_VIDEO:'H3wdxoNhg0M',
   ACCOUNT_API_URL:'https://accounts.globalgarner.com/',
   MAIN_APP_URL:process.env.REACT_APP_BASE_URL+'www.globalgarner'+process.env.REACT_APP_Extension+'/',
   DSP: process.env.REACT_APP_BASE_URL+'dsp.globalgarner'+process.env.REACT_APP_Extension+'/', 
   CP: 'https://partner.globalgarner.com/',
   ACP: 'https://partner.globalgarner.com/',
   MCP: 'https://partner.globalgarner.com/',
   PARTNER: 'https://partner.globalgarner.com/login/partner',
   MENTOR: 'https://mentor.globalgarner.com/',
   SE: process.env.REACT_APP_BASE_URL+'suxesnet.globalgarner'+process.env.REACT_APP_Extension+'/',
   LEVEL_NEXT: process.env.REACT_APP_BASE_URL+'levelnext.globalgarner'+process.env.REACT_APP_Extension+'/',
   LEVEL_NEXT_PRO: process.env.REACT_APP_BASE_URL+'levelnext-pro.globalgarner'+process.env.REACT_APP_Extension+'/',
   GSDC: process.env.REACT_APP_BASE_URL+'gsdc.globalgarner'+process.env.REACT_APP_Extension+'/',
   DSV: process.env.REACT_APP_BASE_URL+'dsv.globalgarner'+process.env.REACT_APP_Extension+'/',
   WATER_TROLLEY: 'https://fp.globalgarner.com/',
   VENDOR: 'https://vendor.globalgarner.com/',
   BUSINESS_PARTNER: 'https://partner.globalgarner.com/login/business-partner',
   FRANCHISEE: 'https://partner.globalgarner.com/login/franchisee',
   UPOS: 'https://upos.globalgarner.com',
   BUSINESS_APP_URL:process.env.REACT_APP_BASE_URL+'business.globalgarner'+process.env.REACT_APP_Extension+'/',
   WATER_FRANCHISE: 'https://business.globalgarner.com/water-franchise/',
   FPV: process.env.REACT_APP_BASE_URL+'fpv.globalgarner'+process.env.REACT_APP_Extension+'/',
   Extension:".com",
   IS_MAINTENANCE_MODE: false
};


export const packageConfigure={

}

export default config;
