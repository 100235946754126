import React from 'react';
import PageTitle from "../core/components/PageTitle";
import Calculator from "./Component/calculator"

export default class Earning extends React.Component {

    constructor(props){
        super(props)
        document.title = 'Calculations | Global Garner'
    }

    render(){
        let metaData = document.getElementsByTagName("meta");
        metaData['description'].content = "Calculate your benefits with Global Garner's wonderful Business opportunity.";
        metaData['Keywords'].content = "earnings, benefits, revenue";
        return(
            <React.Fragment>
                <div className="container-fluid header-below">
                  <div className="container p-s-0">
                    <div className="earning header-below-content">
                        <div className="row text-center mb-5">
                            <div className="col-12 col-xl-12">
                                <h2 className="bp3-text-xlarge bp3-text-white heading">Calculate Earning Potential</h2>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <Calculator />
            </React.Fragment>
        );
    }
}
