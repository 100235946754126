import React from 'react';
import '../../../src/core/styles/_aquash.scss';
import NavDropdown from 'react-bootstrap/NavDropdown'
import Config from "../../Config.js";
export default class Business extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      nav:false
    };
  }

  handleClick = () => {
    this.setState({ show: !this.state.show })
  }
  mobileNav =()=>{
    this.setState({nav:!this.state.nav})
  }
  render() {
    return (
      <React.Fragment>
        <header>
          <nav className="navbar navbar-expand-md aquash-header fixed-top">
            <div className="container">
              <a className="navbar-brand" href="#">
                <img src={require("../image/aquash_logo_header.png")} style={{width:'280px'}}/>
              </a>
              <button className="navbar-toggler aquash-bar-ico" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fas fa-bars" onClick={this.mobileNav}></i>
              </button>
              <div className={this.state.nav===true?"collapse navbar-collapse show":"collapse navbar-collapse"} id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a className="nav-link nav-aquash-link" download="GG_Aquash.pdf" href={require("../brochure/GG_CashAquva_2023.pdf")}   >Download Brochure</a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link nav-aquash-link" href="#"> AQUASH Tutorial</a>
                  </li> */}
                  <li className="nav-item dropdown">
                    <a className="nav-link nav-aquash-link login-link mr-3" href={Config.FPV + 'franchisee/login'}> Login </a>
                    <a className="nav-link nav-aquash-link login-link mr-3" href={Config.BUSINESS_APP_URL + 'CashAqua-Register'}> Register </a>
                    {/*<div className="nav-link nav-aquash-link dropdown-toggle login-link" onClick={this.handleClick} >Login</div>
                    <div className={this.state.show === true ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="navbarDropdown">
                      <a className="dropdown-item" href={Config.BUSINESS_APP_URL + 'water-trolley'}> Login as Water Trolley</a>
                      <a className="dropdown-item" href={Config.BUSINESS_APP_URL + 'trolley-manager'}>Login as Trolley Manager</a>
                      <a className="dropdown-item" href={Config.BUSINESS_APP_URL + 'trolley-pilot'}>Login as Trolley Pilot</a>
                    </div>*/}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    )
  }
}
