import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PageTitle from '../core/components/PageTitle';
import Enterprenur from './components/enterprenur';
import Business from './components/business';
import Config from "../Config";

export default class Partner extends Component {

    render(){
      const BackGroung_Img = {backgroundImage: `url(${require('../core/images/png/RectangleCopy.png')})`};
        return(
            <React.Fragment>
              <PageTitle title='Channel Partner - Global Garner' />
              <div className="container-fluid header-below">
                  <div className="container">
                    <div className="header-below-content">
                      <div className="align-items-center d-flex justify-content-center row text-center">
                        <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 p-s-0 mb-4">
                          <h1 className="bp3-text-xlarge heading bp3-text-white">Let’s Show the world What a Salesman can do</h1>
                          <span className="heading-desc">Global Garner is world's 1st and only postpaid sales company with zero UPFRONT COST,
                              Which helps the vendor to sell their products and services with our Advanced Digital
                              Platform and on-ground sales support. GG also provides the best offer of 100%
                              Cashback/Reward with no upper limit, to its customers, which makes us the first
                              choice for
                              any vendor as we first perform and then seek for our commission only, <b>nothing inadvance.</b>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <Enterprenur />
              <Business />
              <div className="container-fluid py-4 vendorApp" style={BackGroung_Img}>
                  <div className="row d-flex justify-content-center align-items-center h-100">
                      <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12">
                          <div className="download-app">
                              <h4>Download Partner Application</h4>
                              <div className="download-img mb-3">
                                  <a href="https://play.google.com/store/apps/details?id=com.globalgarner.partner" target="_blank" rel="noopener noreferrer">
                                      <img src={require('../core/images/png/Google-Play-&-App-Store-Icon.png')} className="img-fluid mr-2" alt=""/>
                                  </a>
                                  <a href="https://itunes.apple.com/ca/app/global-garner-business-partner/id1202242547" target="_blank" rel="noopener noreferrer">
                                      <img src={require('../core/images/png/Google-Play-&-App-Store-Icon+Copy.png')} className="img-fluid ml-2" alt="" />
                                  </a>
                              </div>
                              <a href={Config.PARTNER} className="bp3-button-large bp3-button mt-4 mb-4" target="_blank" rel="noopener noreferrer" style={{backgroundColor: '#236aee', color : '#FFF'}}>Register as Partner</a>
                            <br/>
                            {/* <Link  to='calculation' >Calculate Income Potential</Link> */}
                          </div>
                      </div>
                  </div>
              </div>
            </React.Fragment>
        );
    }
}
