import React, {Component} from 'react';
import PrivateRoute from './PrivateRoute';
import SpacialRoute from './SpacialRoute';
import { Switch } from 'react-router-dom';
import '../../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
import Config from '../Config'
import {Notfound, Maintance} from "gg-react-utilities";

import Home from '../Home';
import Water from '../Water';
import DSA from '../DSA';
import DSP from '../DSP';
import SE from '../SE';
import Earning from '../earning';
// import Aboutus from '../aboutus';
import Partner from '../partner';
import MCP from '../MCP';
import CP from '../CP';
import ACP from '../ACP';
import Vendor from '../Vendor';
import VendorTerms from "../VendorTerms";
import Business from '../Business';
import Grange from '../Grange'
import Aquash from '../Aquash'
import WaterTrolley from '../Water/Component/WaterTrolley';
import TrolleyManager from '../Water/Component/TrolleyManager';
import TrolleyPilot from '../Water/Component/TrolleyPilot';
import Suxes_Consultancy from '../suxes-consultancy'
import NextLevel from '../NextLevel';
import NextLevelPro from '../NextLevelPro';
import DSV from '../DSV';
import GSDC from '../GSDC';
import Gsdc_Consultancy from '../gsdc-consultancy';

export default class Root extends Component{
    render(){

        return(
            <React.Fragment>
            {
                Config.IS_MAINTENANCE_MODE ?
                    <Switch>
                        <PrivateRoute path="*" component={Maintance}/>
                    </Switch>
                    :
                    <Switch>
                        <PrivateRoute exact path="/" component={Home}/>
                        <PrivateRoute exact path="/water-franchise" component={Water}/>
                        <PrivateRoute exact path="/dsa" component={DSA}/>
                        <PrivateRoute exact path="/suxesnet" component={SE}/>
                        <PrivateRoute exact path="/dsp" component={DSP}/>
                        {/* <PrivateRoute exact path="/aboutus" component={Aboutus}/> */}
                        <PrivateRoute exact path="/channel-partner" component={Partner}/>
                        <PrivateRoute exact path="/mcp" component={MCP}/>
                        <PrivateRoute exact path="/cp" component={CP}/>
                        <PrivateRoute exact path="/acp" component={ACP}/>
                        <PrivateRoute exact path="/vendor" component={Vendor}/>
                        {/* <SpacialRoute exact path="/calculation" component={Earning}/> */}
                        <SpacialRoute exact path="/business" component={Business}/>
                        <SpacialRoute exact path="/grange" component={Grange}/>
                        <SpacialRoute exact path="/CashAqua" component={Aquash}/>
                        <SpacialRoute exact path="/vendor-tc" component={VendorTerms}/>
                        <PrivateRoute exact path="/CashAqua-Register" component={WaterTrolley}/>
                        <PrivateRoute exact path="/trolley-manager" component={TrolleyManager}/>
                        <PrivateRoute exact path="/trolley-pilot" component={TrolleyPilot}/>
                        <PrivateRoute exact path="/suxesnet-landing" component={Suxes_Consultancy}/>
                        <PrivateRoute exact path="/level-next" component={NextLevel}/>
                        <PrivateRoute exact path="/level-next-pro" component={NextLevelPro}/>
                        <PrivateRoute exact path="/dsv" component={DSV}/>
                        <PrivateRoute exact path="/gsdc" component={GSDC}/>
                        <PrivateRoute exact path="/gsdc-landing" component={Gsdc_Consultancy}/>
                        <PrivateRoute path="*" component={Notfound}/>
                    </Switch>
            }
            </React.Fragment>
        );
    }
}