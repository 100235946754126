import React from 'react';
import Config from "../../Config";

export default class DSA extends React.Component {
    render(){
        return(
            <React.Fragment>
                <div className="title-box mb-4">
                    <div className="col-12 text-center">
                        <h1 className="bp3-text-xlarge heading mb-3 mt-3">DSA Register</h1>
                        <p className="mt-3 mb-0">Join us and work from anywhere with no geographical barrier and earn huge incentives<br></br>
                            <small>You have to mandatorily Register as a User First. <a href={Config.MENTOR} rel="noopener noreferrer" target="_blank">Login for DSA</a></small></p>
                    </div>
                </div>
                <div className="row col-12 px-s-0 justify-content-center">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.MENTOR + 'register'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large waterTrolley pr-5 pl-5 w-100">Adviser</a>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.MENTOR + 'register/Consultant'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large trolleyManager pr-5 pl-5 w-100">Consultant</a>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.MENTOR + 'register/Mentor'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large trolleyPilot pr-5 pl-5 w-100">Mentor</a>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.MENTOR + 'register/Chief-Mentor'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large chiefMentor pr-5 pl-5 w-100">Chief Mentor</a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}