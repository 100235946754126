import React from 'react';
import {Link} from "react-router-dom";
import Config from "../../Config";

export default class Home extends React.Component {
    render(){
        return(
            <React.Fragment>
                <div className="title-box mb-4">
                    <div className="col-12 text-center">
                        <span className="bp3-text-xlarge heading mb-3">Login and Registration</span>
                        <p className="mt-3 mb-0">Get ready to earn and enjoy cashback/Reward.Register with Global Garner for Free.<br></br>
                            Before choosing any of the below Registering Options, You have to mandatorily Register as a User First.</p>
                    </div>
                </div>
                <div className="row col-12 px-s-0">
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/cp" className="bp3-button bp3-large cp w-100">CP</Link>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/acp" className="bp3-button bp3-large acp w-100">ACP</Link>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/mcp" className="bp3-button bp3-large mcp w-100">MCP</Link>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/CashAqua" className="bp3-button bp3-large wf w-100">CashAqua</Link>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/vendor" className="bp3-button bp3-large vendor w-100">Vendor</Link>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.PARTNER} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large partner w-100">Partner</a>
                    </div>
					{/*<div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.BUSINESS_PARTNER} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large acp w-100">Business Partner</a>
                    </div>*/}
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href="/dsp" className="bp3-button bp3-large cp w-100">DSP</a>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/dsa" className="bp3-button bp3-large acp w-100">DSA</Link>
                    </div>
                    {/*<div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/suxesnet-landing" className="bp3-button bp3-large mcp w-100">SUXESNET</Link>
                    </div>*/}
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/level-next" className="bp3-button bp3-large wf w-100">Level Next</Link>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/dsv" className="bp3-button bp3-large vendor w-100">DSV</Link>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/level-next-pro" className="bp3-button bp3-large acp w-100">Level Next-Pro</Link>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/gsdc" className="bp3-button bp3-large mcp w-100">GSDC</Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}