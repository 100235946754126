import React from 'react';
import Config from "../../Config";

export default class SE extends React.Component {
    render(){
        return(
            <React.Fragment>
                <div className="title-box mb-4">
                    <div className="col-12 text-center">
                        <h1 className="bp3-text-xlarge heading mb-3 mt-3">GSDC Register</h1>
                        <p className="mt-3 mb-0">Join us and work from anywhere with no geographical barrier and earn huge incentives<br></br>
                            <small>You have to mandatorily Register as a User First. <a href={Config.GSDC} rel="noopener noreferrer" target="_blank">Login for GSDC</a></small></p>
                    </div>
                </div>
                <div className="row col-lg-12 col-md-12 col-sm-12 px-s-0 justify-content-center mx-auto">
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={"#"} rel="noopener noreferrer" className="bp3-button bp3-large trolleyManager pr-5 pl-5 w-100">LCP</a>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={"#"} rel="noopener noreferrer"  className="bp3-button bp3-large chiefMentor pr-5 pl-5 w-100">CCP</a>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={"#"} rel="noopener noreferrer"  className="bp3-button bp3-large trolleyPilot pr-5 pl-5 w-100">SCP</a>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.GSDC + 'register'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large waterTrolley pr-5 pl-5 w-100">NCP</a>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.GSDC + 'register/gcp'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large trolleyManager pr-5 pl-5 w-100">GCP</a>
                    </div>
                    {/*<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.GSDC + 'register/gsdc'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large trolleyPilot pr-5 pl-5 w-100">GSDC</a>
                    </div>*/}
                    {/*<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.GSDC + 'register/vice_president'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large chiefMentor pr-5 pl-5 w-100">VICE PRESIDENT</a>
                    </div>*/}
                </div>
            </React.Fragment>
        );
    }
}