import React from 'react';
import TopBar from "../../core/components/TopBar";
import Config from "../../Config";

export default class TrolleyManager extends React.Component {
    render(){
        return(
            <div className="container mb-5">
                <TopBar title="Login & Register"/>
                <div className="min-height">
                    <div className="title-box mb-4">
                        <div className="col-12 text-center">
                            <h1 className="bp3-text-xlarge heading mb-3 mt-3">Water Trolley Manager</h1>
                            <p className="mt-3 mb-0">Register to access and to have real-time status of your trolley<br></br>
                                You have to mandatorily Register as a User First.</p>
                        </div>
                    </div>
                    <div className="row col-12 px-s-0 justify-content-center">
                        <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                            <a href={Config.WATER_TROLLEY + 'dashboard'} className="bp3-button bp3-large waterTrolley pr-5 pl-5 w-100" rel="noopener noreferrer" target="_blank">Login</a>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                            <a href={Config.WATER_TROLLEY + 'register/3'} className="bp3-button bp3-large trolleyManager pr-5 pl-5 w-100" rel="noopener noreferrer" target="_blank">Register</a>
                        </div>
                    </div>
                    <div className="page-content-right"></div>
                </div>
            </div>
        );
    }
}