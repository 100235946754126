import React from 'react';
import Config from "../../Config";


export default class CP extends React.Component {
    render(){
        return(
            <React.Fragment>
                <div className="title-box mb-4">
                    <div className="col-12 text-center">
                        <span className="bp3-text-xlarge heading mb-3">CP Login & Register</span>
                        <p className="mt-3 mb-0">Register as  Channel Partner and be a part of the best earning opportunity to earn. <br></br>
                            <small>Before registering as CP, you have to mandatorily register as a User First</small></p>
                    </div>
                </div>
                <div className="row col-12 px-s-0 justify-content-center">
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.CP + 'login/cp'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large waterTrolley pr-5 pl-5 w-100">CP Login</a>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.CP + 'cp-registration'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large trolleyManager pr-5 pl-5 w-100">CP Register</a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}