import React from 'react';
import TopBar from "../../core/components/TopBar";
import Config from "../../Config";

export default class WaterTrolley extends React.Component {
    componentDidMount() {
        if(process.env.REACT_APP_ENV==='production'){
            var meta = document.createElement('meta');
            var meta1 = document.createElement('meta');
            meta.name = "description";
            meta.content = "All New concept with this water trolley anyone can buy bottle from this water trolley at normal market price and avail the cashback equivalent to the amount of fuel along with other cashback options .";
            meta1.name = 'keywords';
            meta1.content = "Water Trolley, franchise business, franchise opportunities, Fuel Cashback, cashback";
            document.getElementsByTagName('head')[0].appendChild(meta);
            document.getElementsByTagName('head')[0].appendChild(meta1);
        }
    }
    render(){
        return(
            <div className="container mb-5">
                <TopBar title="Login & Register"/>
                <div className="min-height">
                    <div className="title-box mb-4">
                        <div className="col-12 text-center">
                            <h1 className="bp3-text-xlarge heading mb-3 mt-3">CashAqua Register</h1>
                            <p className="mt-3 mb-0">Join us and work from anywhere with no geographical barrier and earn huge incentives<br></br>
                                <small>You have to mandatorily Register as a User First. <a href={Config.FPV + 'franchisee/login'} rel="noopener noreferrer" target="_blank">Login for CashAqua</a></small></p>
                        </div>
                    </div>
                    <div className="row col-12 px-s-0 justify-content-center">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                            <a href={Config.FPV + 'franchisee/registration/STATE_FRANCHISE'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large waterTrolley pr-5 pl-5 w-100">State Franchise</a>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                            <a href={Config.FPV + 'franchisee/registration/CITY_FRANCHISE'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large trolleyManager pr-5 pl-5 w-100">City Franchise</a>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                            <a href={Config.FPV + 'franchisee/registration/PINCODE_FRANCHISE'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large chiefMentor  pr-5 pl-5 w-100">Pincode Franchise</a>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                            <a href={Config.FPV + 'franchisee/registration/WATER_TROLLEY'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large trolleyPilot pr-5 pl-5 w-100">Water Trolley Franchise</a>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                            <a href={Config.FPV + 'franchisee/registration/SIS'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large partner pr-5 pl-5 w-100">SIS Franchise</a>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                            <a href={Config.FPV + 'franchisee/registration/SHOP'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large vendor pr-5 pl-5 w-100">Shop Franchise</a>
                        </div>
                    </div>
                    <div className="page-content-right"></div>
                </div>
            </div>
        );
    }
}