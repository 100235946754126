import React from 'react';
import {FormGroup, Button} from '@blueprintjs/core';
import {Textbox, Select} from 'react-inputs-validation';
import CheifMentor from './chief';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';

const constant = { 
    CHANNEL_LIST : [
        { id:"", name:"Please Select Channel.."},
        { id: "partner", name: "Partner" },
        { id: "mentor", name: "Mentor" }
    ],
    PARTNER_ROLE_LIST:[
        { id:"", name:"Please Select Partner Role.."},
        { id: "mcp", name: "MCP" },
        { id: "cp", name: "CP" },
        { id: "acp", name: "ACP" },
        { id: "partner", name: "Partner" }
    ],
    MENTOR_ROLE_LIST:[
        { id:"", name:"Please Select DSP Role.."},
        { id: "chief_mentor", name: "Chief DSP" },
        { id: "mentor", name: "Mentor" },
        { id: "consultant", name: "Consultant" },
        { id: "adviser", name: "Adviser" }
    ]
};

export default class Home extends React.Component {
    constructor(props){
        super(props);
        this.state={
            channel:"",
            hasChannelError:false,
            role:"",
            hasRoleError:false,
            type:"no",
            showType:false,
            upos:"",
            hasUPOSError:false,
            validate:false,
            uposOneTime:"",
            uposMonthly:"",
            vendorMonthly:"",
            upvMonthly:"",
            oneTimeNetIncome:"",
            perMonthNetIncome:""
        }
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

    onCalculate(){
        this.toggleValidating(true);
        var upos_one_time = 0;
        var upos_monthly = 0;
        var vendore_monthly = 0;
        var upv_monthly = 0;
        var one_time_net_income = 0;
        var per_month_net_income = 0;
        if (this.state.channel === 'partner') {
            var partner_fix_val = 1000;
            var upos = (partner_fix_val*this.state.upos*12);
            var upv = (upos*100);
            var mcp_fix = 500;
            var mcp_net_fix = 1;

            var cp_fix = 1000;
            var cp_net_fix = 10;

            var acp_fix = 2000;
            var acp_net_fix = 100;

            var partner_fix = 5000;
            var partner_net_fix = partner_fix_val;

            // if (this.state.role === 'mcp' && this.state.type === 'yes')
            // {
            //     upos_one_time = (upos * mcp_fix);
            //     upos_monthly = (upos * 100000 * 0.5/ 100);
            //     vendore_monthly = (upos * 10000 * 0.5/100);
            //     upv_monthly = (upv * 2);
            //     one_time_net_income = (upos_one_time);
            //     per_month_net_income = (upos_monthly + vendore_monthly + upv_monthly);
            // }

            if (this.state.role === 'mcp' && this.state.type === 'no')
            {
                upos_one_time = (upos * mcp_fix);
                upos_monthly = (upos * 100000 * 0.5/100);
                vendore_monthly = (upos * 10000 * 0.25/100);
                upv_monthly = (upv * 1.5);
                one_time_net_income = (upos_one_time);
                per_month_net_income = (upos_monthly + vendore_monthly + upv_monthly);
            }

            // if (this.state.role === 'cp' && this.state.type === 'yes')
            // {
            //     upos_one_time = ((upos * cp_fix) / cp_net_fix);
            //     upos_monthly = ((upos * 100000 * 1.5 / 100) / cp_net_fix);
            //     vendore_monthly = ((upos * 10000 * 1.5 / 100) / cp_net_fix);
            //     upv_monthly = ((upv * 3) / cp_net_fix);
            //     one_time_net_income = (upos_one_time);
            //     per_month_net_income = (upos_monthly + vendore_monthly + upv_monthly);
            //
            // }

            if (this.state.role === 'cp' && this.state.type === 'no')
            {
                upos_one_time = ((upos * cp_fix) / cp_net_fix);
                upos_monthly = ((upos * 100000 * 1.5 / 100) / cp_net_fix);
                vendore_monthly = ((upos * 10000 * 1 / 100) / cp_net_fix);
                upv_monthly = ((upv * 2) / cp_net_fix);
                one_time_net_income = (upos_one_time);
                per_month_net_income = (upos_monthly + vendore_monthly + upv_monthly);

            }

            // if (this.state.role === 'acp' && this.state.type === 'yes')
            // {
            //     upos_one_time = ((upos * acp_fix) / acp_net_fix);
            //     upos_monthly = ((upos * 100000 * 7.5 / 100) / acp_net_fix);
            //     vendore_monthly = ((upos * 10000 * 7.5 / 100) / acp_net_fix);
            //     upv_monthly = ((upv * 20) / acp_net_fix);
            //     one_time_net_income = (upos_one_time);
            //     per_month_net_income = (upos_monthly + vendore_monthly + upv_monthly);
            // }

            if (this.state.role === 'acp' && this.state.type === 'no')
            {
                upos_one_time = ((upos * acp_fix) / acp_net_fix);
                upos_monthly = ((upos * 100000 * 2.5 / 100) / acp_net_fix);
                vendore_monthly = ((upos * 10000 * 5.5 / 100) / acp_net_fix);
                upv_monthly = ((upv * 15) / acp_net_fix);
                one_time_net_income = (upos_one_time);
                per_month_net_income = (upos_monthly + vendore_monthly + upv_monthly);
            }

            if (this.state.role === 'partner')
            {
                upos_one_time = ((upos * partner_fix) / partner_net_fix);
                upos_monthly = ((upos * 100000 * 5 / 100) / partner_net_fix);
                vendore_monthly = 0;
                upv_monthly = 0;
                one_time_net_income = (upos_one_time);
                per_month_net_income = (upos_monthly + vendore_monthly + upv_monthly);

            }
            this.setState({
                uposOneTime:upos_one_time.toLocaleString("en-IN"),
                uposMonthly:upos_monthly.toLocaleString("en-IN"),
                vendorMonthly:vendore_monthly.toLocaleString("en-IN"),
                upvMonthly:upv_monthly.toLocaleString("en-IN"),
                oneTimeNetIncome:one_time_net_income.toLocaleString("en-IN"),
                perMonthNetIncome:per_month_net_income.toLocaleString("en-IN")
            })
        }

        if (this.state.channel === 'mentor') {

            var adviser_fix_val = 1000;
            var upos = (adviser_fix_val*this.state.upos*12);
            var upv = (upos*100);
            var chief_mentor_fix = 500;
            var chief_mentor_net_fix = 1;

            var mentor_fix = 1000;
            var mentor_net_fix = 10;

            var consultant_fix = 2000;
            var consultant_net_fix = 100;

            var adviser_fix = 5000;
            var adviser_net_fix = adviser_fix_val;

            if (this.state.role === 'chief_mentor')
            {
                upos_one_time = upos * chief_mentor_fix;
                upos_monthly = (upos * 100000 * 0.5 / 100);
                vendore_monthly = 0;
                upv_monthly = 0;
                one_time_net_income = (upos_one_time);
                per_month_net_income = (upos_monthly + vendore_monthly + upv_monthly);

            }

            if (this.state.role === 'mentor')
            {
                upos_one_time = ((upos * mentor_fix) / mentor_net_fix);
                upos_monthly = ((upos * 100000 * 1.5 / 100) / mentor_net_fix);
                vendore_monthly = 0;
                upv_monthly = 0;
                one_time_net_income = (upos_one_time);
                per_month_net_income = (upos_monthly + vendore_monthly + upv_monthly);
            }

            if (this.state.role === 'consultant')
            {
                upos_one_time = ((upos * consultant_fix) / consultant_net_fix);
                upos_monthly = ((upos * 100000 * 2.5 / 100) / consultant_net_fix);
                vendore_monthly = 0;
                upv_monthly = 0;
                one_time_net_income = (upos_one_time);
                per_month_net_income = (upos_monthly + vendore_monthly + upv_monthly);
            }

            if (this.state.role === 'adviser')
            {
                upos_one_time = ((upos * adviser_fix) / adviser_net_fix);
                upos_monthly = ((upos * 100000 * 5 / 100) / adviser_net_fix);
                vendore_monthly = 0;
                upv_monthly = 0;
                one_time_net_income = (upos_one_time);
                per_month_net_income = (upos_monthly + vendore_monthly + upv_monthly);

            }

            this.setState({
                uposOneTime:upos_one_time.toLocaleString("en-IN"),
                uposMonthly:upos_monthly.toLocaleString("en-IN"),
                vendorMonthly:vendore_monthly.toLocaleString("en-IN"),
                upvMonthly:upv_monthly.toLocaleString("en-IN"),
                oneTimeNetIncome:one_time_net_income.toLocaleString("en-IN"),
                perMonthNetIncome:per_month_net_income.toLocaleString("en-IN")
            })
        }
    }
    render(){
        return(
            <React.Fragment>
                <div className="container">
                    <div className="calcWrapper">
                        <div className="row">
                            <div className="col-xl-6 col-xl-6 col-xl-6 col-sm-6 col-12 calcForm">
                                <h1 className="text-dark font-18">Calculate your earnings</h1>
                                <FormGroup
                                    label="Select Channel">
                                    <Select
                                        tabIndex="1"
                                        value={this.state.channel}
                                        onChange={(channel) => {
                                            this.setState({ channel,
                                                role:"",
                                                upos:"",
                                                uposOneTime:"",
                                                uposMonthly:"",
                                                vendorMonthly:"",
                                                upvMonthly:"",
                                                oneTimeNetIncome:"",
                                                perMonthNetIncome:""
                                            });
                                        }}
                                        onBlur={e => {}}
                                        validate={this.state.validate}
                                        validationCallback={res =>
                                            this.setState({
                                                hasChannelError:res,
                                                validate: false
                                            })}
                                        optionList={constant.CHANNEL_LIST}
                                        validationOption={{
                                            name: "Channel",
                                            check: true,
                                            required: true,
                                        }}/>
                                </FormGroup>
                                <FormGroup
                                    label="Select Role">
                                    <Select
                                        tabIndex="2"
                                        value={this.state.role}
                                        onChange={(role) => {
                                            this.setState({ role,
                                                upos:"",
                                                uposOneTime:"",
                                                uposMonthly:"",
                                                vendorMonthly:"",
                                                upvMonthly:"",
                                                oneTimeNetIncome:"",
                                                perMonthNetIncome:""
                                                },()=>{
                                                    if(this.state.role==='mcp' || this.state.role==='cp' || this.state.role==='acp'){
                                                        this.setState({
                                                            showType:false
                                                        })
                                                    }else{
                                                        this.setState({
                                                            showType:false
                                                        })
                                                    }
                                                });
                                            }
                                        }
                                        onBlur={e => {}}
                                        validate={this.state.validate}
                                        validationCallback={res =>
                                            this.setState({
                                                hasRoleError:res,
                                                validate: false
                                            })}
                                        optionList={this.state.channel==="partner" ? constant.PARTNER_ROLE_LIST : constant.MENTOR_ROLE_LIST}
                                        validationOption={{
                                            name: "Role",
                                            check: true,
                                            required: true,
                                        }}/>
                                </FormGroup>
                                {/*{this.state.showType ?*/}
                                {/*    <RadioGroup*/}
                                {/*        label={"Are you under GG 2.0? "}*/}
                                {/*        className="d-inline"*/}
                                {/*        onChange={(e) => {*/}
                                {/*            this.setState({*/}
                                {/*                type: e.target.value*/}
                                {/*            })*/}
                                {/*        }}*/}
                                {/*        name="type"*/}
                                {/*        selectedValue={this.state.type}>*/}
                                {/*        <Radio label="Yes" value={"yes"} checked className="ml-3"/>*/}
                                {/*        <Radio label="No" value={"no"} className="ml-3"/>*/}
                                {/*    </RadioGroup>*/}
                                {/*    :null*/}
                                {/*}*/}
                                <FormGroup
                                    label="Expected UPOS to be sold by advisor in a Month">
                                    <Textbox
                                        tabIndex="2"
                                        value={this.state.upos}
                                        placeholder="Enter UPOS"
                                        onChange={(upos) => {
                                            this.setState({ upos:Number(upos) });
                                        }}
                                        onBlur={e => {}}
                                        validate={this.state.validate}
                                        validationCallback={res =>
                                            this.setState({
                                                hasUPOSError:res,
                                                validate: false
                                            })}
                                        validationOption={{
                                            name: "UPOS",
                                            check: true,
                                            required: true,
                                            type:'number'
                                        }}/>
                                </FormGroup>
                                <Button type="submit" intent="primary" className="w-100 mt-3" onClick={
                                    this.onCalculate.bind(this)
                                }>Calculate Now</Button>
                            </div>
                            <div className="col-xl-6 col-xl-6 col-xl-6 col-sm-6 col-12 resultWrapper">
                                <h3>Earning Potential</h3>
                                <div className="grid">
                                    <div className="leftGrid">
                                        <p>UPOS One time</p>
                                        <p>₹ {this.state.uposOneTime ? this.state.uposOneTime : "0.00"}</p>
                                    </div>
                                    <div className="rightGrid">
                                        <p>UPOS Monthly</p>
                                        <p>₹ {this.state.uposMonthly ? this.state.uposMonthly : "0.00"}</p>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="leftGrid">
                                        <p>Vendor Monthly</p>
                                        <p>₹ {this.state.vendorMonthly ? this.state.vendorMonthly : "0.00"}</p>
                                    </div>
                                    <div className="rightGrid">
                                        <p>UPV Monthly</p>
                                        <p>₹ {this.state.upvMonthly ? this.state.upvMonthly : "0.00"}</p>
                                    </div>
                                </div>
                                <div className="footerGrid">
                                    <h3>Net Income</h3>
                                    <div className="grid">
                                        <div className="leftGrid">
                                            <p>One time</p>
                                            <p>₹ {this.state.oneTimeNetIncome ? this.state.oneTimeNetIncome : "0.00"}</p>
                                        </div>
                                        <div className="rightGrid">
                                            <p>Per Month</p>
                                            <p>₹ {this.state.perMonthNetIncome ? this.state.perMonthNetIncome : "0.00"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CheifMentor busicalc={this.state.role}/>
                </div>
            </React.Fragment>
        );
    }
}