import React, {Component} from 'react';
import intro from '../../images/png/bill_pay.png';
import Config from "../../../Config";
import {Button} from "@blueprintjs/core";
import VideoModel from "../../../Home/Component/Video";

export default class Enterprenur extends Component{

    constructor(props){
        super(props);
        this.state={
            isOpen: false,
        };
    }

    render(){
        return(
            <React.Fragment>
                <div className="container">
                    <div className="introWrapper">
                        <div className="introHeader">
                            <h3>Be an entrepreneur, Empower everyone, Create Jobs</h3>
                            <span>Spend some time of your life doing things which no one wants to do and then live rest of your life like no one can afford to.</span>
                        </div>
                        <div className="introContainer">
                            <div className="introImage">
                                <img src={intro} alt="" className="img-fluid" />
                            </div>
                            <div className="introDesc">
                                <div className="introDescPara">
                                    <p>Being an entrepreneur is not an easy job. Now we bring this opportunity to you with a very unique and
                                      systematic structure wherein you will not only be an entrepreneur but will be the most Empowering Profile of
                                      the society you live in, where you will not only empower yourself but also empower the entire society around
                                      you, that too without any fees, deposit or investment in the company. Only with your hard work, you will
                                      provide Jobs and Social security to the society you live in.</p>
                                </div>
                            </div>
                            {
                                Config.PARTNER_YOUTUBE_VIDEO === ''
                                    ?
                                    <Button type="button"
                                            text="Watch channel partner benefits video"
                                            className="btn-link d-flex align-items-center justify-content-start"
                                    >
                                        <span className="bp3-icon bp3-icon-arrow-right ml-3"></span>
                                    </Button>
                                    :
                                    <VideoModel isOpen={this.state.isOpen} data={Config.PARTNER_YOUTUBE_VIDEO} />
                            }
                            {/*<a href="JavaScript:Void(0)" rel="noopener noreferrer">Watch channel partner benefits video</a>*/}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
