import React from 'react';
import Config from "../../Config";

export default class SE extends React.Component {
    render(){
        return(
            <React.Fragment>
                <div className="title-box mb-4">
                    <div className="col-12 text-center">
                        <h1 className="bp3-text-xlarge heading mb-3 mt-3">LEVEL NEXT Register</h1>
                        <p className="mt-3 mb-0">Join us and work from anywhere with no geographical barrier and earn huge incentives<br></br>
                            <small>You have to mandatorily Register as a User First. <a href={Config.LEVEL_NEXT} rel="noopener noreferrer" target="_blank">Login for LEVEL NEXT</a></small></p>
                    </div>
                </div>
                <div className="row col-lg-12 col-md-12 col-sm-12 px-s-0 justify-content-center mx-auto">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.LEVEL_NEXT + 'register'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large waterTrolley pr-5 pl-5 w-100">PARTNER</a>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.LEVEL_NEXT + 'register/bdo'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large trolleyManager pr-5 pl-5 w-100">BDO</a>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.LEVEL_NEXT + 'register/avp'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large trolleyPilot pr-5 pl-5 w-100">AVP</a>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.LEVEL_NEXT + 'register/vice_president'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large chiefMentor pr-5 pl-5 w-100">VICE PRESIDENT</a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}