import React from 'react';
import TopBar from "../core/components/TopBar";
import NextLevel from "./Component/GSDC";

export default class Mobile extends React.Component {
    render(){
        return(
			<div className="container mb-5">
				<TopBar title="Login & Register"/>
				<div className="min-height">
					<NextLevel/>
					<div className="page-content-right"></div>
				</div>
            </div>
        );
    }
}