import React from 'react';
import PageTitle from "../core/components/PageTitle";
import Business from './Component/Business';
import BackGroungImg from "../core/images/png/RectangleCopy.png";
import IphoneImg from "../core/images/png/Google-Play-&-App-Store-Icon+Copy.png";
import AndroidImg from "../core/images/png/Google-Play-&-App-Store-Icon.png";
import Config from "../Config";
//import {Button} from "@blueprintjs/core";

export default class BusinessIndex extends React.Component {
    render(){
        const BackGroung_Img = {backgroundImage: `url(${BackGroungImg})`};
        return(
            <React.Fragment>
                <PageTitle title="World's first website which gives you 100% Cashback on Everything. -    Business" />
                <div className="container-fluid header-below">
                    <div className="container p-s-0">
                        <div className="earning header-below-content">
                            <div className="row text-center mb-5">
                                <div className="col-12 col-xl-12">
                                    <h1 className="bp3-text-xlarge heading">YOUR SEARCH FOR BEST <strike>MARKETING</strike> SALES SOLUTION ENDS HERE</h1>
                                    <p className="bp3-text-medium heading">Hire Us at ZERO COST, Your very own Salesman.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0">
                    <Business/>
                </div>
                <div className="container-fluid py-4 vendorApp" style={BackGroung_Img}>
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12">
                            <div className="download-app">
                                <h4>Download Vendor Application</h4>
                                <div className="download-img mb-3">
                                    <a href="https://play.google.com/store/apps/details?id=vendor.globalgarner.com.vendorapp" target="_blank" rel="noopener noreferrer">
                                        <img src={AndroidImg} className="img-fluid mr-2" alt="" />
                                    </a>
                                    <a href="https://itunes.apple.com/us/app/vendor-global-garner/id1210614253?mt=8" target="_blank" rel="noopener noreferrer">
                                        <img src={IphoneImg} className="img-fluid ml-2" alt="" />
                                    </a>
                                </div>
                                <a href={Config.VENDOR + 'vendor-register'} className="bp3-button-large bp3-button mt-4 mb-4" target="_blank" rel="noopener noreferrer" style={{backgroundColor: '#236aee', color : '#FFF'}}>Register as Vendor</a>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}