import React from 'react';
import TopBar from "../core/components/TopBar";
import Suxes_Consultancy from "./Component/SC";

export default class Mobile extends React.Component {
	render() {
		return (
			<div className="container-fluid mb-5">

				<div className='row'>
					<div className='container p-0'>
						<div className='col-12 p-0'>
							<TopBar title="Suxes Consultancy" suxesHeader={true} />
						</div>
					</div>
				</div>

				<div className="row">
					<Suxes_Consultancy />
					<div className="page-content-right"></div>
				</div>
			</div>
		);
	}
}