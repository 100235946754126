import React from 'react';
import TopBar from "../core/components/TopBar";
import Water from "./Component/Water";

export default class Mobile extends React.Component {



    render(){
		let metaData = document.getElementsByTagName("meta");
		metaData['description'].content = "Grow your business with Global Garner and get enormous earning benefits.";
		metaData['Keywords'].content = "franchise, franchise business, franchise for sale, franchise opportunities, franchise business for sale, franchise india";
		return(
			<div className="container mb-5">
				<TopBar title="Login & Register"/>
				<div className="min-height">
					<Water/>
					<div className="page-content-right"></div>
				</div>
            </div>
        );
    }
}