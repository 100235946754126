import React from 'react';
import Home from "./Component/Home"
import localVendorImg from '../core/images/png/local_vendor.png';
import JoinUsImg from '../partner/images/png/Join-Us.png';
import UposImg from '../Business/images/png/UPOS.png';
import franchiseImage from '../Business/images/png/franchise.png';
import {Link} from "react-router-dom";
import Config from "../Config";
import {Button} from '@blueprintjs/core';
import VideoModel from './Component/Video';

export default class Mobile extends React.Component {
    constructor(props){
        super(props);
        document.title = 'Business| Global Garner'
        this.state={
            isOpen: false,
        };
    }

    componentDidMount() {

    }

    render(){
      let metaData = document.getElementsByTagName("meta");
      metaData['description'].content = "Grow your business with Global Garner and get enormous earning benefits. Expand Your Business with Global Garner!";
      metaData['Keywords'].content = "Business, profitable business, profit, investing, occupation, earnings, benefits, revenue, Business Opportunity";
        return(
            <React.Fragment>
                <div className="container mb-5 min-height mt-s-5 mt-sm-4 mt-md-3 mt-lg-4 mt-xl-0">
                    <Home/>
                </div>

                <div className="container-fluid p-0">
                    <div className="businessWrapper">
                        <div className="calcForm py-5 Digitalization">
                            <div className="container">
                                <div className="row px-s-0 d-flex flex-lg-row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center d-flex align-items-center">
                                        <img src={require('../Business/images/png/user_benefits.png')} alt="" className="img-fluid mt-5 mt-s-0 mb-s-4"/>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-start flex-column">
                                        <h3 className="mb-4 text-muted">
                                            <b>GG’s User Benefits</b>
                                        </h3>
                                        <p className="mb-4">
                                            <b>A Universal App to save what you spend</b>
                                        </p>
                                        <p className="mb-4">Explore the widest assortment of shopping options and get upto 100% guaranteed cash back/reward on all your shoppings.</p>
                                        {
                                            Config.UPOS_YOUTUBE_VIDEO === ''
                                                ?
                                                <Button type="button"
                                                        text="Watch Video"
                                                        className="btn-link d-flex align-items-center justify-content-start"
                                                >
                                                    <span className="bp3-icon bp3-icon-arrow-right ml-3"></span>
                                                </Button>
                                                :
                                                <VideoModel isOpen={this.state.isOpen} data={Config.USER_BENEFITS_VIDEO} />
                                        }
                                        <p>
                                            <a href={Config.MAIN_APP_URL+'user-benefits'} rel="noopener noreferrer" target="_blank" className="bp3-button-large bp3-button mt-4 mb-4 viewBtn">View More</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="calcForm py-5 Exclusive">
                            <div className="container">
                                <div class="row px-s-0 d-flex flex-lg-row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-start flex-column">
                                        <h3 className="mb-4 text-muted">
                                            <b>Become a vendor</b>
                                        </h3>
                                        <p className="mb-4">
                                            <b>Become a GG vendor & experience new growth in your Business</b>
                                        </p>

                                        <p className="mb-4">Get your own personal Salesman at zero upfront cost and pay on real sales only</p>

                                        <VideoModel isOpen={this.state.isOpen} data={Config.VENDOR_YOUTUBE_VIDEO} />

                                        <a href={Config.BUSINESS_APP_URL+'business'} rel="noopener noreferrer" target="_blank" className="bp3-button-large bp3-button mt-4 mb-4 viewBtn">View More</a>

                                        {/* <Link to="/business"  target="_blank"  className="bp3-button-large bp3-button mt-4 mb-4" style={{backgroundColor: '#236aee', color : '#FFF'}}>View More</Link> */}
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex text-center align-items-center">
                                        <img src={localVendorImg} alt="" className="img-fluid mt-5 mt-s-0 mb-s-4"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="calcForm py-5 Digitalization">
                            <div className="container">
                                <div className="row px-s-0 d-flex flex-lg-row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center d-flex align-items-center">
                                        <img src={UposImg} alt="" className="img-fluid mt-5 mt-s-0 mb-s-4"/>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-start flex-column">
                                        <h3 className="mb-4 text-muted">
                                            <b>UPOS</b>
                                        </h3>
                                        <p className="mb-4">
                                            <b>Ab sabka Business hai Apna Business</b>
                                        </p>
                                        <p className="mb-4">UPOS help you build your business by leveraging the myriad of services provided by Global Garner</p>
                                        {
                                            Config.UPOS_YOUTUBE_VIDEO === ''
                                                ?
                                                <Button type="button"
                                                        text="Watch Video"
                                                        className="btn-link d-flex align-items-center justify-content-start"
                                                >
                                                    <span className="bp3-icon bp3-icon-arrow-right ml-3"></span>
                                                </Button>
                                                :
                                                <VideoModel isOpen={this.state.isOpen} data={Config.UPOS_YOUTUBE_VIDEO} />
                                        }

                                        <p>
                                            <a href={Config.UPOS} rel="noopener noreferrer" target="_blank" className="bp3-button-large bp3-button mt-4 mb-4 viewBtn">View More</a>
                                            <a href={Config.UPOS + '/calculate'} rel="noopener noreferrer" target="_blank" className="bp3-button-large bp3-button mt-4 mb-4 ml-4 viewBtn">Calculate potential earnings</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="calcForm py-5 Exclusive">
                            <div className="container">
                                <div className="row px-s-0 d-flex flex-lg-row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-start flex-column">
                                        <h3 className="mb-4 text-muted">
                                            <b>GG Aquash</b>
                                        </h3>

                                        <p className="mb-4">
                                            <b>Paani pe Petrol ka Cashback</b>
                                        </p>

                                        <p className="mb-4">
                                            Best earning opportunity, Grab your own advanced Water Trolley to sell packaged drinking water with exclusive cashback options.
                                        </p>

                                        {
                                            Config.FRANCHISE_YOUTUBE_VIDEO === ''
                                                ?
                                                <Button type="button"
                                                        text="Watch Video"
                                                        className="btn-link d-flex align-items-center justify-content-start"
                                                >
                                                    <span className="bp3-icon bp3-icon-arrow-right ml-3"></span>
                                                </Button>
                                                :
                                                <VideoModel isOpen={this.state.isOpen} data={Config.FRANCHISE_YOUTUBE_VIDEO} />
                                        }

                                        <p>
                                            <a href={Config.BUSINESS_APP_URL+'aquash'} rel="noopener noreferrer" target="_blank" className="bp3-button-large bp3-button mt-4 mb-4 viewBtn">View More</a>
                                        </p>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center d-flex align-items-center">
                                        <img src={franchiseImage} alt="" className="img-fluid mt-5 mt-s-0 mb-s-4"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="calcForm py-5 Digitalization">
                            <div className="container">
                                <div className="row px-s-0 d-flex flex-lg-row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center d-flex align-items-center">
                                        <img src={require('../Grange/image/g3.png')} alt="" className="img-fluid mt-5 mt-s-0 mb-s-4"/>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-start flex-column">
                                        <h3 className="mb-4 text-muted">
                                            <b>GG Grange</b>
                                        </h3>
                                        <p className="mb-4">
                                            <b>Family Fresh Kirana Store & UPOS Store</b>
                                        </p>
                                        <p className="mb-4">
GG Grange is a Grocery Mart with a fusion of brick and mortar model and online shopping, having a wide assortment of Food, Vegetables, Beverages and Household item integrated with digital UPOS Mall of 20 crore + Products and Services with exclusive cashback options up to ₹1 crore.</p>
                                        {/* {
                                            Config.UPOS_YOUTUBE_VIDEO === ''
                                                ?
                                                <Button type="button"
                                                        text="Watch Video"
                                                        className="btn-link d-flex align-items-center justify-content-start"
                                                >
                                                    <span className="bp3-icon bp3-icon-arrow-right ml-3"></span>
                                                </Button>
                                                :
                                                <VideoModel isOpen={this.state.isOpen} data={Config.UPOS_YOUTUBE_VIDEO} />
                                        } */}

                                        <p>
                                            <a href={Config.BUSINESS_APP_URL+'grange'} rel="noopener noreferrer" target="_blank" className="bp3-button-large bp3-button mt-4 mb-4 viewBtn">View More</a>
                                            {/* <a href={Config.UPOS + '/calculate'} rel="noopener noreferrer" target="_blank" className="bp3-button-large bp3-button mt-4 mb-4 ml-4 viewBtn">Calculate potential earnings</a> */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="calcForm py-5 Exclusive">
                            <div className="container">
                                <div className="row px-s-0 d-flex flex-lg-row">
                                   
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-start flex-column">
                                        <h3 className="mb-4 text-muted">
                                            <b>Leadership Team</b>
                                        </h3>

                                        <p className="mb-4">
                                            <b>Join our Team and Earn Huge Incentives</b>
                                        </p>

                                        <p className="mb-4">Open the new avenue of earning and be a part of new digital Revolution</p>

                                        {
                                            Config.PARTNER_YOUTUBE_VIDEO === ''
                                                ?
                                                <Button type="button"
                                                        text="Watch Video"
                                                        className="btn-link d-flex align-items-center justify-content-start"
                                                >
                                                    <span className="bp3-icon bp3-icon-arrow-right ml-3"></span>
                                                </Button>
                                                :
                                                <VideoModel isOpen={this.state.isOpen} data={Config.PARTNER_YOUTUBE_VIDEO} />
                                        }

                                        <p>
                                        <a href={Config.BUSINESS_APP_URL+'channel-partner'} rel="noopener noreferrer" target="_blank" className="bp3-button-large bp3-button mt-4 mb-4 viewBtn">View More</a>
                                      
                                            {/* <Link to="/channel-partner" className="bp3-button-large bp3-button mt-4 mb-4" style={{backgroundColor: '#236aee', color : '#FFF'}}>View More</Link> */}
                                            {/* <Link to="/calculation" className="bp3-button-large bp3-button mt-4 mb-4 ml-4" style={{backgroundColor: '#236aee', color : '#FFF'}}>Calculate potential earnings</Link> */}
                                        </p>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center d-flex align-items-center">
                                        <img src={JoinUsImg} alt="" className="img-fluid mt-5 mt-s-0 mb-s-4"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}
