import React from 'react';
import PageTitle from "../core/components/PageTitle";

export default class VendorTerms extends React.Component {
    render(){
        return(
            <React.Fragment>
                <PageTitle title='Website Terms of Sale' />
                <div className="container-fluid header-below">
                    <div className="container p-s-0" >
                        <div className="earning header-below-content" style={{'height':'320px'}}>
                            <div className="row text-center mb-5">
                                <div className="col-12 col-xl-12">
                                    <h1 className="bp3-text-xlarge heading">Website Terms of Sale</h1>
                                    <p>Last updated on 24th May 2018</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container term">
                    <div className="row">
                        <div className="col-md-12">
                            <section className="vendor_tc_list">
                                <p className="text-justify">THESE WEBSITE TERMS AND CONDITIONS FOR VENDOR <b> ("TERMS OF SALE” or
                                    “TERMS”) </b>IS AN
                                    ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC CONTRACT FORMED UNDER
                                    INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER AND THE AMENDED
                                    PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS STATUTES AS
                                    AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THESE TERMS OF SALE DO NOT
                                    REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE SUBJECT TO THE PROVISION OF
                                    ARTICLE 22.</p>
                                <p className="text-justify">
                                    THESE TERMS OF SALE IS A <b>LEGALLY BINDING DOCUMENT</b> BETWEEN <b>YOU AND GLOBAL
                                    GARNER SALES SERVICES PRIVATE LIMITED </b>(BOTH TERMS DEFINED BELOW). THESE TERMS OF
                                    SALE WILL BE EFFECTIVE UPON YOUR ACCEPTANCE OF THE SAME (DIRECTLY OR INDIRECTLY IN
                                    ELECTRONIC FORM OR BY MEANS OF AN ELECTRONIC RECORD OR MANUALLY SIGNED COPY) AND
                                    WILL GOVERN THE RELATIONSHIP BETWEEN YOU AND GLOBAL GARNER (DEFINED BELOW)
                                    INCLUDING WITH RESPECT TO THE LISTING, MARKETTING, SALE AND DELIVERY OF ANY
                                    PRODUCTS THROUGH THE WEBSITE AND PHYSICAL STORES OF THE VENDOR ALONG WITH THE
                                    USE AND ACCESS OF THE VENDOR PANEL.
                                </p>
                                <p className="text-justify">THIS DOCUMENT IS PUBLISHED AND SHALL BE CONSTRUED IN ACCORDANCE WITH THE
                                    PROVISIONS OF RULE 3 (1) OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES)
                                    RULES, 2011 UNDER INFORMATION TECHNOLOGY ACT, 2000 THAT REQUIRES PUBLISHING THE
                                    RULES AND REGULATIONS, PRIVACY POLICY AND USER AGREEMENT FOR ACCESS TO OR USAGE
                                    OF THE WEBSITE.</p>
                                <p className="text-justify">These Terms of Sale of the Website located at the URL <a href="www.globalgarner.com" target="_blank"> www.globalgarner.com </a>(the “Website”)
                                    and mobile
                                    application under the name and style "Global Garner" (the <b>“Mobile App”</b>) is between GLOBAL
                                    GARNER SALES
                                    SERVICES PRIVATE LIMITED (hereinafter referred to as <b>"GLOBAL GARNER "</b>' or <b>"We"</b> or
                                    <b>"Us"</b> or <b>"Our"</b> or
                                    “Company”) and Vendor who can be an individual/firm/company who is registered as a Vendor of
                                    GLOBAL
                                    GARNER (hereinafter referred to as "You" or "Your" or "Yourself" or "Vendordescribe the
                                    conditions on which
                                    GLOBAL GARNER offers You access to the Website and such other services as are incidental and
                                    ancillary thereto
                                    (<b>"Services"</b>).</p>
                                <i>(An electronic copy of these terms with your details duly filled-in will be sent to your
                                    registered email address or will
                                    be uploaded on the Vendor Dashboard panel, take a printout and then self-attest all pages along
                                    with required
                                    documents should be again uploaded by you on website, through your Vendor Dashboard)</i>
                                <p className="text-justify">GLOBAL GARNER and YOU are hereinafter individually referred to as
                                    <b>“Party”</b> and collectively as <b>“Parties”</b>, as
                                    the context may require.</p>
                                <p className="text-justify">PLEASE READ THESE TERMS OF SALE CAREFULLY BEFORE USING OR REGISTERING ON THE
                                    WEBSITE OR ACCESSING ANY MATERIAL, INFORMATION OR SERVICES THROUGH THE WEBSITE. IF
                                    YOU DO NOT AGREE WITH THESE TERMS OF SALE, PLEASE DO NOT USE THE WEBSITE AND
                                    COMPLETE VENDOR REGISTRATION. IF YOU DO NOT WANT TO BE VENDOR OF THE COMPANY, YOU
                                    CAN STILL USE THE WEBSITE/MOBILE APP AS A CUSTOMER OF COMPANY. </p>
                                <p className="text-justify">If You are accessing the GLOBAL GARNER WEBSITE or accessing the Services
                                    through the GLOBAL GARNER
                                    mobile application, then while these Terms will apply to such use, there may be additional terms
                                    (such as the
                                    conditions imposed by mobile application stores like Apple's iTunes, Android's play store,
                                    Microsoft's store from time
                                    to time) which will govern the use of the mobile application. These additional terms to the
                                    extent applicable are hereby
                                    deemed to be incorporated in these Terms of Sale by way of reference.</p>
                                <ol className="padding-0 vendor_tc_list">
                                    <li>
                                        <b>GENERAL</b>
                                        <ol>
                                            <li>
                                                <p className="text-justify">
                                                    GLOBAL GARNER SALES SERVICES PRIVATE LIMITED is a company incorporated under the
                                                    Indian Companies Act, 2013, having its registered office at 3rd floor, IFFCO
                                                    Bhawan, Shivranjani cross road,
                                                    Satellite, Ahmedabad, Gujarat- 380015, Gujarat and is an intermediary in the
                                                    form of an online marketplace
                                                    and is limited to managing the Website to enable Vendors to exhibit, advertise,
                                                    display, make available and
                                                    offer to sell the products and to enable buyers to purchase the products so
                                                    offered <b>("Products"),</b> and other
                                                    incidental Services thereto <b>("Services")</b> including use of the Website by
                                                    the Vendors. The company shall not
                                                    take any responsibility of the product/service being offered to sale and the
                                                    language/content used for
                                                    advertising the product. The Company has further developed a unique concept
                                                    where it offers cash back
                                                    options to its Buyers and Vendors in accordance with the provisions of Cash-back
                                                    Annexure -I herein
                                                    attached.
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-justify">
                                                    Under this unique concept of cash back to the users, the Global Garner shall
                                                    give cash back through
                                                    Systematic Cash Back process to its users with its sole responsibility and
                                                    Vendor shall not have any
                                                    responsibility and liability regarding the cash back to its users. This cash
                                                    back arrangement is between Global
                                                    Garner and its users. Global Garner gives cashback/ reward through three
                                                    different process, which depends
                                                    upon the option chosen by the user. process of Systematic Cash Back and other
                                                    cashback option has been
                                                    explained in detail in the annexure – 1.
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-justify">
                                                    These Terms are subject to revision by GLOBAL GARNER at any time and hence You
                                                    are requested to
                                                    carefully read these Terms from time to time before performing Your obligations
                                                    and/or using the Website.
                                                    Accordingly, the Company shall, at least twenty-four (24) hours prior to the
                                                    implementation of the revision
                                                    shall notify at your dash board. The revised Terms shall also be made available
                                                    on the Website. You are
                                                    requested to regularly visit the Website to view the most current Terms. In the
                                                    event such a facility is provided
                                                    on the Website, You can determine when GLOBAL GARNER last modified any part of
                                                    the these Terms by
                                                    referring to the <b>"LAST TERMS"</b> legend provided in that document. It shall
                                                    be Your responsibility to check
                                                    these Terms periodically for changes through link, of which is given on your
                                                    dashboard at our website.
                                                    GLOBAL GARNER may require You to provide Your direct or indirect consent to any
                                                    update in a specified
                                                    manner before further use of the Website and the Services. If no such separate
                                                    consent is sought, Your
                                                    continued use of the Website and/or performance of obligations, following such
                                                    changes, will constitute Your
                                                    acceptance of those changes. In case you decide to reject the same, your account
                                                    will stand temporarily
                                                    suspended till the issue is amicably resolved between you & Global Garner Sales
                                                    Services Pvt. Ltd.
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>REPRESENTATIONS AND WARRANTIES </b>
                                        <ol>
                                            <li>
                                                <b>Joint Representations of Warranties</b>
                                                <p>The Parties hereby represent and warrant to each other as under: </p>
                                                <ol>
                                                    <li>The Parties have all requisite power and authority to execute these Terms,
                                                        deliver and perform their
                                                        obligations under these Terms and have been fully authorized by all
                                                        requisite corporate actions to do
                                                        so; and
                                                    </li>
                                                    <li>
                                                        <p className="text-justify">
                                                            The execution and performance of these Terms by either Party does not
                                                            and will not violate any provision of
                                                            any existing agreement, law, rule, regulation, any order or judicial
                                                            pronouncement.
                                                        </p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <b>Representations and Warranties of the Vendor</b>
                                                <p> You agree, acknowledge and understand that, at all times, for the entire
                                                    Duration of these Terms, You
                                                    will:</p>
                                                <p>a) Abide by the Provisions of these Terms, the Company Policies and the other
                                                    Website policies, as may be
                                                    applicable to you;</p>
                                                <p>b) Not offer for sale/sell/deliver any Banned Product/s on the Website;</p>
                                                <p> c) Deliver the Product/s to the Buyers in a timely manner consistent with
                                                    the provisions of these Terms; and</p>
                                                <p> d) Deliver the Product/s in accordance with all applicable laws, rules,
                                                    regulations, governmental orders, etc.,
                                                    and applicable codes of practice, now or hereafter in effect, relating to
                                                    Your performance under these Terms;</p>
                                                <p> e) You are using the Website provided and owned by the Company;</p>
                                                <p> f) The permission granted by the Company to use the Website as an online
                                                    market place is on a non-exclusive
                                                    basis;</p>
                                                <p> g) The Company reserves the right to deny access to, or revoke, such
                                                    permission to use the Vendor Panel and/or
                                                    Website at any time;</p>
                                                <p> h) The Company shall have the right to remove the listing of any Product/s
                                                    being offered for sale by You;</p>
                                                <p> i) The Company shall have the right to offer discounts, run promotion
                                                    campaigns on the Selling Price to the
                                                    Buyers from the Company’s Commission. You shall not object to the provision
                                                    of such discounts given by
                                                    The Company;</p>
                                                <p> j) Any and all data derived as a result of these Terms will be owned by the
                                                    Company and You shall have the
                                                    right to utilize such data for the duration of these Terms to fulfil Your
                                                    obligations hereunder;</p>
                                                <ol>
                                                    <li>
                                                        <p className="text-justify">
                                                            You represent that You are competent to contract and are not
                                                            disqualified from contracting under any law in
                                                            India.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className="text-justify">
                                                            You have procured and shall maintain all licenses and registrations
                                                            required for selling the Product/s online
                                                            or otherwise for the entire Duration of these Terms.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className="text-justify">
                                                            You agree, represent and warrant that You shall not describe Yourself as
                                                            an agent or representative of the
                                                            Company or make any representations to any Buyer or any third party or
                                                            give any warranties which are of
                                                            such a nature that the Company may be required to undertake, or be
                                                            liable for, whether directly or indirectly.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className="text-justify">
                                                            You agree, represent and warrant that You shall not, for the entire
                                                            Duration of these Terms, offer the Product/s
                                                            listed on the Website, to any other website or through any other
                                                            platform, at a price which is less than the
                                                            Selling Price, as listed on the Global Garner Website.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className="text-justify">
                                                            You agree to attend to, and resolve, the Buyers’ queries with regard to
                                                            the delivery of the Product/s and the
                                                            quantity and quality of the Product/s at Your discretion from the date
                                                            of receipt of such queries.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className="text-justify">
                                                            You hereby represent and warrant to the Company that there are no
                                                            restrictions, hindrances or encumbrances
                                                            of any nature which, in any manner, restrict the performance of the
                                                            obligations by You under these Terms.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className="text-justify">
                                                            You shall be responsible for payment of Your own taxes and any
                                                            taxes/levies/cess applicable on the Product/s
                                                            sold through the Website, and shall indemnify and hold harmless, the
                                                            Company, from any liability in this
                                                            regard.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className="text-justify">
                                                            You hereby declare and confirm that You deal only in original,
                                                            legitimate and genuine Product/s which are
                                                            either self-manufactured and/or procured from legitimate channels and in
                                                            compliance with all the legal
                                                            requirements. You further declare that You shall not violate the
                                                            intellectual property rights of any third party
                                                            and for any breach or violation of such intellectual property rights,
                                                            You shall be held solely responsible and
                                                            shall further indemnify the Company in that regard.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className="text-justify">
                                                            The content of the Product/s, the text descriptions, graphics or
                                                            pictures regarding the Product/s being
                                                            uploaded on the Website and the Product/s packaging, shall not be
                                                            obscene, libellous, defamatory or
                                                            scandalous or which is capable of hurting the religious sentiments of
                                                            any segment of the population or
                                                            constitute an infringement of any intellectual property rights of any
                                                            person or entity.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className="text-justify">
                                                            You represent that You shall not, at any time, use any intellectual
                                                            property of the Company in any manner
                                                            without the prior written consent of the Company. You also represent
                                                            that You shall not purchase any the
                                                            Company URLs and meta tags on the Internet without the prior written
                                                            consent of the Company.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className="text-justify">You represent and warrant that if You are found indulging in providing of
                                                        false or misleading information or
                                                        provision of defective or counterfeit Product/s, then the Company may
                                                        initiate civil and/or criminal
                                                        proceedings against You and the Company may, at its sole discretion,
                                                        suspend, block, restrict, or cancel Your
                                                        registration on the Website and /or disqualify / bar You from selling the
                                                            Product/s on the Website.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                        <li>
                                            <b>OBLIGATIONS OF THE VENDOR</b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        You shall maintain records of all the Product/s purchased by the Buyers through
                                                        the Website, all returns,
                                                        refunds, etc., as may be required for audit and regulatory purposes and for the
                                                        service purposes of buyers of
                                                        the Website.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        During the Duration of these Terms, You shall appoint a representative, who
                                                        shall be The Company’s point
                                                        of contact for any and all matters related to these Terms, including all
                                                        additional sales and delivery related
                                                        matters.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        You shall be solely responsible and liable for any complaints and queries of
                                                        Buyers with respect to the
                                                        Product/s, delayed delivery or non-delivery of the Product/s purchased or any
                                                        complaints with respect to the
                                                        quality or quantity of the Product/s delivered.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        You shall be solely responsible for making any representations or warranties
                                                        with respect to the quality
                                                        of the Product/s to the Buyer, including all relevant Product/s warranties.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        All orders placed on the Website are covered under the “100% Cash-back”
                                                        programme or as opted by the
                                                        User. You agree to fulfil the commitments made under the abovementioned program.
                                                        The said programme
                                                        grants the Buyer cash-back rewards for being a regular purchasing Product/s
                                                        using the Website.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        You shall, at all times, comply with all applicable laws including without
                                                        limitation compliance with laws
                                                        relating to sales tax, VAT and as implemented by Central/state Government time
                                                        to time
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        In case you wish to change or expand or close your business, you shall inform to
                                                        the company by giving due
                                                        notice period of one month and pay any unpaid commission to the company.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>PURCHASE AND DELIVERY OF PRODUCT/S</b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        You agree that You will abide by the provisions of these Terms and The Company
                                                        Policies, including
                                                        any amendments thereto made by the Company from time to time which may be made
                                                        without prior
                                                        notice to You.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        delivery mechanisms as per these Terms. However, it is agreed by the Company
                                                        that in the instance that You
                                                        have no means to provide these facilities, then Company shall aid the Vendor and
                                                        assist in the same at an
                                                        extra cost.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        You shall upload the Product/s listings for the sale of the Product/s in the
                                                        appropriate category, through
                                                        the Vendor Panel. You shall also be required to provide all details relevant to
                                                        the sale/purchase of the
                                                        Product/s, including the Selling Price, an informative description of each
                                                        Product/s (including but not limited
                                                        to the length, breadth and height of the Product/s) and its contents, by way of
                                                        text descriptions, graphics, or
                                                        pictures or videos which will be listed on the Website with the Selling Price
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        You represent that You shall provide accurate Product/s information on the
                                                        Vendor Panel/Website which
                                                        must not be misleading and shall describe the actual condition of the Product/s.
                                                        The Company rejects any
                                                        liability for the all Product/s sold to Buyers thorough its website and the
                                                        responsibility to ensure Product/s
                                                        quality and accuracy vests solely with You. If the sold Product/s does not match
                                                        the Product/s description
                                                        displayed on the Website, the Vendor shall be responsible.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        You shall neither attempt nor aid another to sell any Product/s falling in the
                                                        category of the
                                                        Company “Banned Product/s and Services” List on this Website. If such a
                                                        Product/s is listed online for
                                                        sale, the liability for the same is solely borne by You and necessary action may
                                                        be taken against You by
                                                        the Company. The Company shall be entitled to block all such Product/s and shall
                                                        also have the right to
                                                        suspend or terminate Your access to the Vendor Panel and the Website or
                                                        terminate these Terms
                                                        forthwith.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">When the sale of a Product/Service has been approved and confirmation of the same
                                                    has been issued to the
                                                    Buyer, You cannot refuse to comply with the said order and reverse the transaction,
                                                    unless the Buyer
                                                    cancels the order within the requisite time-frame as decided by You. Only in case if
                                                    you have no stock of
                                                    ordered product/service or in case you are not able to deliver the ordered
                                                    product/service in the particular
                                                        area, you can deny to deliver the ordered product/service.</p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        For all orders placed on the Website, payments shall be collected by the Global
                                                        Garner, in the mode (i.e., by
                                                        Payment Gateway Facility or Cash-On-Delivery) as opted for by the Buyers. You
                                                        agree that the online
                                                        Payment Gateway Facility provided by the Company is not a banking service nor a
                                                        financial service
                                                        undertaken by it but the Company is merely a facilitator, providing an automated
                                                        online electronic payment
                                                        system, using the existing authorized banking infrastructure and credit card
                                                        payment gateway
                                                        networks for the transactions on the Website. Further, by providing the online
                                                        Payment Gateway Facility,
                                                        the Company is neither acting as a trustee nor acting in a fiduciary capacity
                                                        with respect to any
                                                        transaction on the Website or collected in person by courier agencies.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        The Vendor Panel will reflect the change in status upon the receipt of the
                                                        payment of the Selling Price
                                                        of the Product/s, intimating You of the successful completion of the sale.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        The Company shall provide the necessary back-end infrastructure, like
                                                        call-centre, order management
                                                        system, etc., for capturing the Buyer/order details placed on the Vendor Panel
                                                        at an extra cost. The
                                                        orders placed by the Buyer will be forwarded to You or reflected in the Vendor
                                                        Panel. You shall package
                                                        the Product/s in accordance with its own packaging guidelines and dispatch the
                                                        Product/s to the Buyer.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        You shall bear the sole responsibility to ensure that the purchased Product/s
                                                        are dispatched to the Buyer,
                                                        within such time period which The Company may prescribe from time to time, along
                                                        with all the required
                                                        information, manuals, accessories (where applicable) warranty documents (where
                                                        applicable) and any other
                                                        relevant documents, to enable the Buyer to make optimum use of the Product/s
                                                        purchased. You shall also
                                                        issue a corresponding Invoice in the name of the Buyer, which Invoice shall be
                                                        sent both electronically as
                                                        PDFs and in hard copy format, to the Buyer along with the Product/s. You shall
                                                        be responsible to update the
                                                        Vendor Panel to reflect this development.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        The default delivery model provided by The Company for the delivery of purchased
                                                        Product/s to the
                                                        Buyers is the “Seller Self Model” as detailed herein. In this Model, You will be
                                                        responsible for
                                                        packaging and shipping the Product/s to the Buyer via any courier agencies of
                                                        Your choosing.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        You shall keep the Company informed promptly on any information that shall
                                                        impact the delivery of
                                                        Product/s to the Buyer or you can use delivery service provider suggested by the
                                                        company for smooth
                                                        operations.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        You confirm and understand that selling and delivering wrong, fake, duplicate,
                                                        spurious, counterfeit,
                                                        damaged, defective Product/s through the Website will cause great prejudice and
                                                        harm to the reputation and
                                                        goodwill of the Company and may also cause harm and prejudice to the Buyers. If
                                                        the Company receives any
                                                        complaint from any Buyer, investigation will commence and if the allegations
                                                        made are found to be true,
                                                        then You shall be liable to refund the amount stated below in Article 14 and
                                                        shall be liable to pay pecuniary
                                                        damages to the Company.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>NON-DELIVERY/RETURN POLICY</b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        The Buyer of the Product/s shall be subject to and comply with the provisions of
                                                        Your respective
                                                        individual Policies relating to the Return of Products and cases of No-Delivery,
                                                        where the Product/s has
                                                        not been delivered due to any reason/fault attributable to You or where a faulty
                                                        Product/s is received.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        The Parties agree and acknowledge that the Company shall be entitled to
                                                        recover/adjust any
                                                        outstanding amount due and payable by You to the Company under these Terms on
                                                        providing just and
                                                        sufficient cause, and You undertakes not to object to such recovery/adjustment.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        You agrees that You will abide by the provisions of these Terms, the Company
                                                        Policies and all policies
                                                        that may be framed by the Company from time to time.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>TRANSFER OF PRODUCT/S OWNERSHIP AND CONSUMER RIGHTS </b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        The ownership of the Product/s purchased will be transferred to the Buyer after
                                                        successful delivery of the
                                                        same at the destination provided by the Buyer, until which the ownership of the
                                                        Product/s shall vest with
                                                        the You alone. Any damage in transit on account of inadequate/unsuitable
                                                        packaging will be attributable only
                                                        to You. The Company shall not be responsible for any unsatisfactory or delayed
                                                        performance of Yours
                                                        including delays as a result of the Product/s being out of stock.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        You will offer standard manufacturers or seller’s warranty actually associated
                                                        with the Product/s.
                                                        However, the Parties agree that repair, replacement or refund of money (subject
                                                        to necessary deductions)
                                                        will be given to the Buyer against manufacturing defect or damage. You shall
                                                        issue a suitable, duly
                                                        stamped, manufacturer’s warranty card to the Buyer with the Product/s at the
                                                        time of dispatch of the
                                                        Product/s, if applicable. The Parties also agree and acknowledge that the
                                                        primary and sole
                                                        responsibility for redressal of the Buyer’s complaints will rest solely with You
                                                        at all times.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>INTELLECTUAL PROPERTY </b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        You understand and acknowledge that the Intellectual Property used by Yourself,
                                                        associated therewith, is the
                                                        property of the Company.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        You shall not use the Company’s Intellectual Property or name as part of Your
                                                        corporate or business name.
                                                        Except as expressly provided otherwise herein, You shall not, by virtue of these
                                                        Terms, acquire any right,
                                                        title or interest in or to any the Company’s Intellectual Property. The use by
                                                        You of any of the Company’s
                                                        Intellectual Property is authorized only for the purposes herein set forth, and
                                                        upon termination of these Terms
                                                        for any reason such authorization shall cease.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        All Product/s, documentation, company marks, the Company’s Confidential
                                                        Information and Intellectual
                                                        Property Rights and all copies thereof must be returned to the Company upon req
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>LIMITATION OF LIABILITY </b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        It is the express intention of the Parties that each Party shall be responsible
                                                        for its obligations
                                                        hereunder, and that the Company shall not be liable under these Terms to You for
                                                        any loss, damage, liability
                                                        or expense with respect to Your obligations pursuant to these Terms. Any loss or
                                                        damage sustained by the
                                                        Company in connection with the performance of Your obligations hereunder shall
                                                        solely be Your
                                                        responsibility and for Your account.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        In no event shall the Company, be liable to You, any Buyer or any other person
                                                        or entity for any special,
                                                        consequential, incidental, indirect, exemplary or punitive damages, however
                                                        caused, arising out of these
                                                        Terms, the performance of the services by the Company for the Buyer or the
                                                        provision of the
                                                        Product/s by the Company, regardless of the form of action, whether for breach
                                                        of contract, breach of
                                                        warranty, tort, negligence, strict Product/s liability, infringement or
                                                        otherwise (including, without
                                                        limitation, damages based on wilfulness, loss of profits, data, files, or
                                                        business opportunity), and whether
                                                        or not the Company has been advised of the possibility of such damages. This
                                                        limitation shall apply
                                                        notwithstanding any failure of essential purpose of any limited remedy provided
                                                        herein.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>INDEMNIFICATION </b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        You agree to indemnify and hold the Company, its directors, officers and
                                                        employees (collectively the
                                                        <b>“Indemnified Party”</b>) harmless from any claim made against the Indemnified
                                                        Party by a third party to the
                                                        degree that such claim arises as a result of Your gross negligence or wilful
                                                        misconduct in connection with
                                                        Your obligations hereunder.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>REPORT ABUSE </b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        In the event You come across any abuse or violation of these Terms of Sale or if
                                                        You become aware of any
                                                        objectionable content on the Website, or if You believe Your intellectual
                                                        property rights have been violated
                                                        in any manner through the Website, please contact GLOBAL GARNER.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>COMMUNICATIONS</b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        When You use the Website or send emails or other data, information or
                                                        communication to GLOBAL
                                                        GARNER, You agree and understand that You are communicating with GLOBAL GARNER
                                                        through
                                                        electronic records and You consent to receive communications via electronic
                                                        records from GLOBAL
                                                        GARNER periodically and as and when required. GLOBAL GARNER will communicate
                                                        with You by email
                                                        or by notices on Website or electronic records on the Website or on Your mobile
                                                        number which will be
                                                        deemed adequate service of notice / electronic record to the maximum extent
                                                        permitted under any applicable
                                                        law.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>MISCELLANEOUS PROVISIONS</b>
                                            <ol>
                                                <li>
                                                    <b>Additional Remedies</b>
                                                    <p className="text-justify">
                                                        Subject to the provisions of Article 14 hereinabove, the Parties agree that
                                                        damages may not be an adequate
                                                        remedy and the Parties shall be entitled to an injunction, restraining order,
                                                        right for recovery, specific
                                                        performance or other equitable relief to restrain any breach or enforce the
                                                        performance of the covenants,
                                                        representations, warranties and obligations contained in these Terms. These
                                                        injunctive remedies are
                                                        cumulative and are in addition to any other rights and remedies that the Parties
                                                        may have at law or in equity,
                                                        including without limitation a right for damages.
                                                    </p>
                                                </li>
                                                <li>
                                                    <b>Entire Legal Document</b>
                                                    <p className="text-justify">
                                                        These Terms (including the recitals, annexures and schedules hereto and other
                                                        documents and instruments
                                                        referred to in these Terms) together constitute the entire legal document
                                                        between the Parties with respect to
                                                        the subject matter hereof and shall supersede any and all prior Terms,
                                                        understandings, assurances, including
                                                        letters of intent and term sheets, either oral or in writing, between all or any
                                                        of the Parties in relation to all or
                                                        any of such matters.
                                                    </p>
                                                </li>
                                                <li>
                                                    <b>Further Assurance</b>
                                                    <p className="text-justify">
                                                        The Parties to these Terms, at all times, shall act in good faith in the
                                                        discharge of their obligations under these
                                                        Terms and not do anything which would constitute a contravention of its terms.
                                                        The Parties to these Terms
                                                        shall from time to time execute and deliver all such further documents and do
                                                        all acts and things as the other
                                                        Party may reasonably require to effectively carrying on the full intent and
                                                        meaning of these Terms.
                                                    </p>
                                                </li>
                                                <li>
                                                    <b>Notices</b>
                                                    <p className="text-justify">
                                                        Any notice required or permitted by these Terms shall be in writing and shall be
                                                        delivered in person, or sent
                                                        by overnight globally recognized courier addressed to the designated
                                                        representative of any Party or such other
                                                        representatives as may be designated).
                                                    </p>
                                                    <table className="bp3-html-table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    If to Vendor –
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    On its registered address as entered on website/ app
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <b>If to Global Garner then
                                                                        To, Legal-Global Garner Sales Services Private Limited</b>
                                                                    <p>
                                                                        3rd Floor, IIFCO Bhawan<br />
                                                                        Shivranjani cross road<br />
                                                                        Satellite<br />
                                                                        Ahmedabad – 380015, Gujarat<br />
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Email : <a href="/cdn-cgi/l/email-protection#f9959c9e9895b99e95969b98959e988b979c8bd79a9694"><span className="__cf_email__" data-cfemail="f29e9795939eb2959e9d90939e9593809c9780">[email&#160;protected]</span>
                                                                    .com</a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </li>
                                                <li>
                                                    <b>Relationship</b>
                                                    <p className="text-justify">
                                                        The Parties hereto have agreed that their respective rights and obligations with
                                                        regard to their business
                                                        relationship between them inter se will be interpreted, acted upon and governed
                                                        solely in accordance with the
                                                        provisions of these Terms.
                                                    </p>
                                                </li>
                                                <li>
                                                    <b>Severability</b>
                                                    <p className="text-justify">
                                                        If any of the Articles of these Terms or the application thereof to any Person
                                                        or circumstance becomes invalid,
                                                        prohibited or unenforceable to any extent for any reason including by reason of
                                                        any law or regulation or
                                                        government policy, these Terms shall be considered severable as to such Articles
                                                        and such Article/s shall be
                                                        inoperative and shall not be part of the consideration moving from one party to
                                                        another and the remainder of
                                                        these Terms and the application of such Article/s to Persons or circumstances
                                                        other than those as to which it
                                                        is held invalid or unenforceable shall not be affected thereby, and each
                                                        provision of these Terms shall be
                                                        valid and enforceable to the fullest extent permitted by Law. Any invalid or
                                                        unenforceable Article of these
                                                        Terms shall be replaced with an Article, which is valid and enforceable and most
                                                        nearly reflects the original
                                                        intent of the unenforceable provision.
                                                    </p>
                                                </li>
                                                <li>
                                                    <b>Waiver</b>
                                                    <p className="text-justify">
                                                        The Parties may extend the time for performance of any of the obligations or
                                                        other acts of the other Party;
                                                        waive any inaccuracies in the representations and warranties of the other Party
                                                        contained in these Terms or
                                                        in any certificate, instrument or document delivered pursuant to these Terms; or
                                                        waive compliance with any
                                                        of the covenants, agreements or conditions for the benefit of such Party
                                                        contained in these Terms.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>

                                    <li>
                                            <b>DISPUTE RESOLUTION </b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        In the event of any dispute arising out of or relating to these Terms,
                                                        termination or invalidity thereof shall be
                                                        referred to and finally resolved by an arbitration under the provisions of the
                                                        Arbitration and Conciliation Act,
                                                        1996 and or its statutory modifications. The Arbitration proceedings shall be
                                                        conducted in English. The
                                                        principles of natural justice must be observed in the arbitration proceedings.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        The Parties shall be treated with equality and impartiality and each of them
                                                        should be given an
                                                        opportunity to present its case. The arbitrator/s shall not be bound by the
                                                        strict rules of law and evidence and
                                                        the Arbitral Tribunal shall be free to decide the matter as to do substantial
                                                        justice between the Parties. The
                                                        seat of Arbitration shall be at Ahmedabad. The Arbitration award shall be final
                                                        and binding.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>GOVERNING LAW </b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        The said Terms shall be governed and construed in accordance with the laws of
                                                        the India. Subject to
                                                        provisions of Article 18, the Civil Courts at Ahmedabad shall have exclusive
                                                        jurisdiction to try and entertain
                                                        any dispute arising out of or in connection with these Terms.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>TERMINATION</b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        Global Garner may terminate Your registration with immediate effect if the You
                                                        commit any Default with
                                                        respect to Your obligations under these Terms.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        Global Garner shall have a right to terminate this relationship with immediate
                                                        effect, without any liability
                                                        attributable to it, if You commit a Default.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-justify">
                                                        For the purpose of these Terms, ANY ONE or more of the following shall
                                                        constitute an event of default (the
                                                        <b>“Default”</b>):
                                                    </p>
                                                    <ol>
                                                        <li>
                                                            <p className="text-justify">
                                                                Failure to Perform – Your failure to perform Your obligations under
                                                                these Terms;
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="text-justify">
                                                                Breach of Representation or Warranty - Any breach of any representation
                                                                or warranty made by You under
                                                                these Terms;
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="text-justify">
                                                                In the event You are subjected to Insolvency events or commencement of
                                                                any proceedings under the
                                                                applicable bankruptcy laws, if such proceedings are not stayed for a
                                                                period of Sixty (60) days after its
                                                                admission.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="text-justify">
                                                                The “Insolvency Events” referred above are as follows:-
                                                            </p>
                                                            <ol>
                                                                <li>
                                                                    <p className="text-justify">
                                                                        Being unable to pay its debts or having no reasonable prospect
                                                                        of being able to pay them;
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p className="text-justify">
                                                                        Entering into liquidation;
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p className="text-justify">
                                                                        Passing a resolution for creditors winding up;
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p className="text-justify">
                                                                        Entering into a composition in satisfaction of its debts;
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p className="text-justify">
                                                                        Suffering the appointment of an administrator, receiver or
                                                                        administrative receiver;
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p className="text-justify">
                                                                        In the event of any Default as specified in this Article, Global
                                                                        Garner shall recover from You all costs and
                                                                        expenses, including reasonable attorneys' fees, incurred in
                                                                        connection with any action taken with respect
                                                                        thereto.
                                                                    </p>
                                                                </li>
                                                            </ol>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>EFFECT OF TERMINATION</b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        On expiration or earlier termination of these Terms:
                                                    </p>
                                                    <ol>
                                                        <li>
                                                            <p className="text-justify">
                                                                You shall cease to list, market, sell and deliver Your Product/s and/or
                                                                cannot make use of the Global Garner
                                                                marks or the Vendor Panel;
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="text-justify">
                                                                All the Cash-back amounts and other payments being made to You shall
                                                                cease to be made with immediate
                                                                effect;
                                                            </p>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <b>VERIFICATION AND AFFIRMATION </b>
                                            <ol>
                                                <li>
                                                    <p className="text-justify">
                                                        You hereby agree that upon receiving the Terms Acceptance Notification, You
                                                        shall take a print out a
                                                        copy of the Terms provided by GLOBAL GARNER and shall signed along with your
                                                        firm’s/company’s
                                                        rubber stamp, if any, with Your photograph and signature on every page. You are
                                                        duty bound to ensure that
                                                        the copy of the Terms is duly uploaded on Vendor Dashboard, which will be
                                                        provided to you by the company.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                <br></br>
                                <p className="text-justify">
                                    I/we hereby declare that I/we have personally seen & verified the terms of this agreement & I
                                    hereby declare that
                                    I understand the concept of Global Garner’s and ready to work with full responsibility with
                                    Global Garner Sales
                                    Services Private Limited as a Registered Vendor.
                                </p>
                               <br></br>
                                <h3 className="text-center">ANNEXURE - I</h3>
                                <h4><b>GG User Cashback options</b></h4>
                                <ol className="vendor_tc_list">
                                    <li>
                                        <b>SCB: Systematic Cash Back FIFO: </b>
                                        <p className="text-justify">
                                            It is a systematic cashback process in which cashback / reward is given in 4 unequal
                                            instalments of Rs.500, Rs.1000,
                                            Rs.3000, Rs.5500 each as per unique auto tagging system, where you have to do nothing
                                            except for generating your
                                            own UPVs by transacting through us for your daily needs
                                        </p>
                                        <p className="text-justify">
                                            Cash back will start after an UPV^ is generated and thereafter new user (counted
                                            globally under your UPV) doing the
                                            same and after approval Cash Back will start <b>through SCB</b> as per tagging process
                                            given below on the basis of First
                                            in first out
                                        </p>
                                        <u>
                                            INSTANTLY GET MOVIE VOUCHERS /REWARD OR ANY OTHER VOUCHER WORTH Rs.200 AND
                                            Rs.10000/- AS GG COINS ON UPV GENERATION
                                        </u>
                                       <br></br>
                                       <br></br>
                                        <table className="bp3-html-table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>AFTER 6</th>
                                                    <th>NEW USERS</th>
                                                    <th>UNDER YOUR UPV</th>
                                                    <th>5% CASH BACK WILL BE PAID</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>AFTER 36</td>
                                                    <td>NEW USERS</td>
                                                    <td>UNDER YOUR UPV</td>
                                                    <td>10% CASH BACK WILL BE PAID</td>
                                                </tr>
                                                <tr>
                                                    <td>AFTER 216</td>
                                                    <td>NEW USERS</td>
                                                    <td>UNDER YOUR UPV</td>
                                                    <td>30% CASH BACK WILL BE PAID</td>
                                                </tr>
                                                <tr>
                                                    <td>AFTER 1296</td>
                                                    <td>NEW USERS</td>
                                                    <td>UNDER YOUR UPV</td>
                                                    <td> 55% CASH BACK WILL BE PAID</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br></br>
                                        <p><b>THEREFORE, 100% CASHBACK / REWARD WILL BE PAID. This process will entirely depend upon
                                            new
                                            UPV generated in the system and tagged under your UPV and tagging will be done as per
                                            above algorithm. </b></p>
                                    </li>
                                    <li>
                                        <b>SCB RPP: Random Pick Process</b>
                                        <p className="text-justify">
                                            It is a Cashback/reward process in which cashback/reward will be processed through
                                            random selection of UPVs
                                            generated in a month. Cashback/reward will be processed monthly where qualifiers (users
                                            whose UPV has been
                                            generated) are arranged in a pool and then number of qualifiers and cashback percentage
                                            are decided as per above
                                            process called SCB but unlike SCB process of FIFO (First in and First Out), where the
                                            UPVs are given cashback as
                                            per tagging system as and when new UPVs are generated, in this process of RPP , the
                                            <u><b>system will randomly pick the
                                                UPV for any given slab/amount of cashback but there will be a guarantee of
                                                everyone
                                                getting the
                                                cashback/reward from minimum 2% to maximum 100%.</b></u> Amount of cashback
                                            /reward given to any
                                            user will be
                                            randomly decided by the system but the eligibility for the number of qualifiers under
                                            any given cashback slab will
                                            solely depends upon SCB process only. The user which comes first or which comes last
                                            into the system has an equal
                                            probability of getting any amount of cashback due to the shopping done by others.
                                        </p>
                                    </li>
                                    <li>
                                        <b>. Instant Cashback</b>
                                        <p className="text-justify">It is a cashback in which you can Shop and get guaranteed 2% to 10%
                                            instant cashback
                                            without waiting to form an
                                            UPV or without worrying about new UPVs tagging under your purchases. Cashback will be
                                            processed instantly as
                                            soon as you make a purchase of any amount and this purchase will not be counted under
                                            SCB process.
                                        </p>
                                        <b>You can choose from any of the three options (1,2,3). You are always allowed to change
                                            your cashback option
                                            anytime or during any purchase</b>
                                    </li>
                                    <li>
                                        <b> Buddy UPV Cashback</b>
                                        <p className="text-justify">
                                            It is cashback/reward where you can get movie tickets worth Rs.200 as cashback/reward or
                                            any other reward/voucher
                                            when your referred User generates an UPV. You don’t have to wait for referral’s cashback
                                            initiation process. You will
                                            get cashback <b>instantly</b> as soon as UPV is generated by your friend. So, don’t wait
                                            just refer your friends
                                        </p>
                                    </li>
                                    <li>
                                        <b>Referral Cashback</b>
                                        <p>Refer your friends and also get 10% of the Cash Back given to them (option 1, 2 &3).<br />
                                            Refer a Vendor and get 5% of the commission given to us by the referred Vendors.</p>
                                    </li>
                                </ol>
                                <p className="text-justify">
                                    Cashback/ reward will be given in the form of GG MONEY which can be transferred to your bank
                                    account after
                                    deducting applicable taxes and charges or it can be in the form of reward in the way of vouchers
                                    etc. You can also start
                                    collecting your GG COINS* by generating UPVs and by referring friends which can be redeemed with
                                    new purchases
                                    to the extent of 5% of the bill amount and GG-Coins up to Rs. 10000/ will be converted into
                                    GG-Money with 4th SCB
                                    Cashback Pay out and then you can transfer it to his Bank account.</p>
                                <h4 className="text-center"><b>Registration Process for Vendor</b></h4>
                                <p className="text-justify">
                                    Global Garner Sales Services Pvt. Ltd. provides you the world’s first digital and unique platform with Triple Verified
                                    users which offers 100% Cash back on each & every product and services you buy with no upper limit. Global Garner
                                    offers you a Long Term Business Association opportunity. There shall not be any exclusivity for any vendor unless it
                                    is approved by the Company on the condition that 50% volume of the business is to be routed through Global Garner’s
                                    platform. If you want an exclusivity, in that case you have sign a separate agreement for exclusivity for your business
                                    with the company.
                                </p>
                                <p>
                                    Now to become a Global Garner Vendor (GG Vendor), complete the following easy Steps:
                                    Go to <a href="www.globalgarner.com" target="_blank">www.globalgarner.com</a> and fill your details online along with Aadhar No., PAN No. and Bank Details. On
                                    Completion of your registration process you will get GG Vendor ID. Now you are registered with us as a GG Vendor
                                    for doing business in your allotted Area.
                                </p>
                                <h4><b><u>Eligibility Criteria for Global Garner Vendor </u></b></h4>
                                <p># Any Individual/Company/Firm(Partnership/Proprietor) who is selling product and services. </p>
                                <h4><b><u>Global Garner Requirements </u></b></h4>
                                # Shop and Establishment Registration<br />
                                # Bank Account Mandatory<br />
                                # Service Providers should have PAN Number<br />
                                # VAT Registration, TIN Registration, CST Registration, GST Registration<br />
                                # Space for proper placement of GG Signboard<br />
                                # One dedicated staff with basic computer knowledge for GG Accounting<br />
                                # Willingness to build Relationships and provide best services to the customers<br />
                                # Transparent and Honest Business Transaction<br />
                                # Should give bills with GG barcode to all GG users<br />
                                <h4><b><u>Documents required for Vendor Recruitment as an Individual</u></b></h4>
                                # Passport Size Photographs 2 No.’s<br />
                                # Address Proof (2 Copies)<br />
                                # PAN Card (2 Copies)<br />
                                # Canceled Cheque/ Bank Statement/ Passbook<br />
                                # NACH(National Automated Clearing House) form dully signed (for commission)<br />
                                <h4><b><u> Documents required for Vendor Recruitment as a (Shop/Firm/Company)</u></b></h4>
                                # Passport Size Photographs 2 No.’s<br />
                                # Address Proof Business (2 Copies)<br />
                                # PAN Card of Shop/Firm/Company and Authorised Signatory<br />
                                # Cancelled Cheque/ Bank Statement/ Passbook<br />
                                # NACH form dully signed (for commission)<br />
                                # Incorporation and Establishment Certificate<br />
                                # Letter or Board Resolution for Authorized Signatory<br />
                                # ID Proof of person who is signing the Agreement<br />
                                # Letter or Board Resolution for Authorized Signatory (for Company/Firm)<br />
                                <h4><b><u> Duties and Responsibilities of Vendor</u></b></h4>
                                # Vendor is responsible for creating awareness and run the Global Garner cash back program.<br />
                                # Responsible for selling all products and Services selected to all GG Users at the location for which he/she is
                                finalized as GG Vendor.<br />
                                <h4><b><u> Benefits of associating as GG Vendor</u></b></h4>
                                # 100% Cash back with No upper Limit to all your customers through SCB(Systematic Cash Back Process).<br />
                                # Or 2% to 100% Cashback through SCB RPP or 2% to 10% through instant cashback.<br />
                                # 100% Free Shop Listing and Digitalization Package worth Rs.50000.00 (Exclusive Website by GG)<br />
                                # Increase in Customer Base will enable the Vendor to Expand his/her Business.<br />
                                # Privilege of choice for Basic Commission or Cash Back Commission.<br />
                                # GG Vendor registered under Cash Back Commission plan will receive the Commission Back through SCB process
                                which he/she paid to Global Garner as commission.<br />
                                # Opportunity to sell exclusive product and services through exclusive GG tie-ups.<br />
                                # Full training support from our Local Representative.<br />
                            </section>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}