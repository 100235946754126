import React, {Component} from 'react';
import {Button, Classes, Dialog} from "@blueprintjs/core";
import Youtube from '../../core/components/Youtube';


export default class Video extends Component{

    constructor(props){
        super(props);

        this.state={
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: props.isOpen,
            usePortal: true,
            selectedOption:null
        }
        this.handleClose =  this.handleClose.bind(this);
    }

    handleClose=()=>{
        this.setState({
            isOpen: false
        })
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextprops){
        this.setState({
            isOpen: nextprops.isOpen
        });
    }

    render(){
        return(
            <React.Fragment>
                <Button type="button"
                        text="Watch Video"
                        className="btn-link d-flex align-items-center justify-content-start"
                        onClick={()=>{
                            this.setState({isOpen:true})
                        }}>
                    <span className="bp3-icon bp3-icon-arrow-right ml-3"></span>
                </Button>
                <Dialog
                    isOpen={this.state.isOpen}
                    title={this.props.title}
                    className={this.props.className?this.props.className:''}
                    onClose={this.handleClose}
                    canOutsideClickClose={true}
                    canEscapeKeyClose={true}
                >
                    <div className={Classes.DIALOG_BODY+ ' m-0'} style={{background: '#BED7F9'}}>
                        <Youtube videoId={this.props.data} onEnd={() => this.setState({videoPlayed: false})} className="w-100" style={{height: '430px'}} />
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }

}