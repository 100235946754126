import React, {Component} from 'react';
import intro from '../../images/png/bill_pay.png';

export default class Business extends Component{
    constructor(props){
        super(props);
          this.state = {
            data:[
              {
                title:'Join our Leadership Team and earn huge incentives for the lifetime',
                image:require('../../images/png/Join-Us.png'),
                desc:'Be a part of the revolutionary concept and change your lifestyle. Join our Leadership Team and work as per your convenience to earn lucrative incentives. Being a part of our Team, you have to recruit vendors on our platform and sell other products and services of the Company to earn.'
              },
              {
                title:'Develop your Business and Be with Your Family',
                image:require('../../images/png/Develop-your-Business.png'),
                desc:'Now you can develop your business in your own city and social circle, without changing your place. We give you this opportunity to develop your business in your own network and earn for your family and be with them. Work Today & Earn Forever.'+
                     'Your efforts today will benefit you not only now but generations to come.'
              },
              {
                title:'Earn When Anyone Spends in your Area',
                image:require('../../images/png/Earn-When-Anyone-Spends-in-your-Area.png'),
                desc:'Earn When Anyone Spends in your Area Whenever anyone in your allotted area makes any purchase or spends any money through us, you actually earn money. So, you just have to create a market initially and earn eternally.'
              },
              {
                title:'Empowering Every Vendor',
                image:require('../../images/png/Empower-Vendors.png'),
                desc:'Digitally empower every business owner by bringing them on our Platform. You can earn by assisting all category of vendor, be it a small or big business to increase their sales and expand their revenue. '
              },
              {
                title:'Create new Entrepreneurs',
                image:require('../../images/png/create-new-entrepreneurs.png'),
                desc:'Recruit like-minded team, make them Entrepreneurs and Empower the society. You also earn, when your team earns. '
              },
              {
                title:'Most Liked and Trusted Person',
                image:require('../../images/png/Most-Liked.png'),
                desc:'When you empower the society, you become the Most Trusted and Respected person of the society. You will the catalyst for users to save, what they spend, for vendors to increase their sales, for partners to increase their earnings.'
              },
            ]
          }
    }

    render(){
        return(
            <React.Fragment>
              {this.state.data.map((res,i)=>{
                let styleClass = i%2===0?'cashbackWrapper py-5 bg-light':'cashbackWrapper py-5 rightWrap'
                return(
                  <div className={styleClass}>
                      <div className="container">
                        {i%2===0?
                          <div className="cashbackContainer row revert">
                            <div className="col-xl-6 col-xl-6 col-xl-6 col-sm-6 col-12">
                              <div className="businessGrid">
                                <h3>{res.title}</h3>
                                <p>{res.desc}</p>
                              </div>
                            </div>
                            <div className="col-xl-6 col-xl-6 col-xl-6 col-sm-6 col-12 mt-s-4">
                              <div className="businessImage">
                                <img src={res.image} alt="" className="img-fluid" />
                              </div>
                            </div>
                          </div>
                          :
                          <div className="cashbackWrapper py-5 rightWrap">
                              <div className="container">
                                <div className="cashbackContainer row ">
                                  <div className="col-xl-6 col-xl-6 col-xl-6 col-sm-6 col-12">
                                    <div className="businessImage">
                                      <img src={res.image} alt="" className="img-fluid" />
                                    </div>
                                  </div>
                                  <div className="col-xl-6 col-xl-6 col-xl-6 col-sm-6 col-12 mt-s-4">
                                    <div className="businessGrid">
                                      <h3>{res.title}</h3>
                                      <p>{res.desc}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        }
                      </div>
                  </div>
                )
              })}


            </React.Fragment>
        );
    }
}
