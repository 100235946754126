import React from 'react';
import { Link } from "react-router-dom";
import Config from "../../Config";
import { style } from '../../core/styles/_sc.scss'
import MultiCarousel from './ReactMultiCarousel';
import Enterprenuer_group from "../../core/images/png/Businee_Marketing.png";
import mission from "../../core/images/JPG/mission.jpg";
import vision from "../../core/images/JPG/vision.jpg";

export default class Suxes_Consultancy extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid ban_pos p-0">

                    {/* Banner Start */}
                    {/*<div className='container-fluid'>
                        <div className="row">
                            <div className='col-12'>
                                <MultiCarousel />
                            </div>
                        </div>
                    </div>*/}
                    {/* Banner End */}  

                    {/* People Group Start */}
                    <div className='container'>
                        <div className="row align-item-center">
                            <div className="col-lg-4 col-md-12 col-sm-12 d-flex align-items-center justify-content-center mb-4">
                                <div className="banner_content">
                                    <img src={Enterprenuer_group} />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12 d-flex ">
                                <div className="banner_content">
                                    <p>Global Source for Digital Commerce (GSDC),  is a leading consultancy firm specializing in digital commerce, providing comprehensive support to start-ups and MSMEs worldwide. We offer a range of services, from developing strategic plans to implementing marketing campaigns, to help our clients achieve their business goals. Our team of experts works closely with clients to ensure their needs are met, with a focus on delivering high-quality and customized solutions.</p>
                                    <p>Our mission at GSDC is to empower MSMEs and support entrepreneurship development across various sectors. We believe that small and medium-sized businesses are the backbone of the global economy, and we are committed to helping them thrive in an increasingly competitive and digital landscape.</p>
                                </div>
                            </div>
                            <div className="gsdc-content col-lg-12 mt-4 mb-3" >
                                <p>We are dedicated to creating more opportunities for women entrepreneurs, promoting youth employment, and enhancing skill-building and entrepreneurship.</p>
                                <p>We have a strong network of vendors and social entrepreneurs, enabling us to provide clients with a comprehensive range of goods and services. Our team of professionals, with expertise in business development, marketing, sales, and strategy, bring a wealth of experience to our clients. We emphasize innovation, excellence, and collaboration in our approach to ensure we deliver tailored solutions to meet the unique needs of our clients.</p>
                                <p>At GSDC, we are committed to helping start-ups and MSMEs unlock their full potential and achieve sustainable growth in the global market.</p>
                                <p>Our support extends to helping businesses establish, operate, and expand their operations worldwide.</p>
                                <Link to={"/gsdc"} className="btn custom_btn">
                                    Log In / Sign Up
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* People Group End */}

                    {/* Vision Mission Start */}
                    {/*<section className='ptb-60 custome-bg mt-5'>
                        <div className='container'>
                            <div className='row d-flex justify-content-center m-0'>
                                <div className="col-lg-12 p-0">
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12 col-sm-12 MV d-flex align-items-stretch'>
                                            <div className='MV_box'>
                                                <div className='MV_box_img'>
                                                    <img className='vision' src={vision} alt="Vision" />
                                                </div>
                                                <div className='MV_box_content'>
                                                    <h3>Vision</h3>
                                                    <p>Promote nation building through team, collective & Collaborative success by creating an ECOSYSTEM for Rural Development, Women and Youth empowerment,MSME Empowerment & Social Entrepreneurship.</p>
                                                    <p>Design, build processes that would make your business more enjoyable & profitable by providing solutions to your non-core functions.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12 col-sm-12 MV d-flex align-items-stretch'>
                                            <div className='MV_box'>
                                                <div className='MV_box_img'>
                                                    <img className='mission' src={mission} alt="Mission" />
                                                </div>
                                                <div className='MV_box_content'>
                                                    <h3>Mission</h3>
                                                    <p>To cause transformation by redefining systems, processes, values to enrich people's lives</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>*/}
                    {/* Vision Mission End */}

                    {/* Ceenter para Section Start */}
                    {/*<div className="container mt-2">
                        <div className="row">
                            <div className="content">
                                <h3>Freedom to work in the area of your choice & liking. We promote and sell products and services
                                    through our Digital Mall in Health & Wellness, Education, Agriculture, Finance, FMCG sectors.</h3>
                                <p>For details, please contact us.</p>
                                <p>In addition, Suxes Consultancy provides all support services for MSME entrepreneurs, start-ups to
                                    make their business profitable & sustainable by taking care of non-core functions. We redefine
                                    success by creating strategies, systems & processes.</p>
                            </div>
                        </div>
                    </div>*/}
                    {/* Ceenter para Section End */}

                    {/* Suxes Management Team Start */}
                    {/*<section className="mb-5 custome-bg2 mt_170">
                        <div className="container-fluid">
                            <div className='SUXEX_team'>
                                <div className="our_team">
                                    <div className="row d-flex justify-content-center m-0'">
                                        <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 text-center">
                                            <div className="our_team_header">
                                                <h4>Suxes Management Team</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container'>
                                        <div className='row Team_bg_box'>
                                            <div className="col-lg-4">
                                                <div className="our_team_card">
                                                    <h4>Subhash Tanwar</h4>
                                                    <p>Founder & CEO (Professional telecom engineer from reputed engineering institute), Hard-core Sales Expert, Rich corporate experience at CEO level in multinational companies in India & Africa, Extensive Political & Social Network, Business Mentor.</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="our_team_card">
                                                    <h4>Unmesh Bagwe</h4>
                                                    <p>Co-Founder & COO (Professional telecom engineer from reputed engineering institute) Hard core Techy Project Manager (ex-PMP), Top-level corporate career in India & Europe, Corporate Trainer, Long term association with progressive social movement.</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="our_team_card our_team_card3">
                                                    <h4>Premendra Kashid</h4>
                                                    <p>Head of Sales and Business Development (MBA-Fin & Mktg) with 25+ yrs experience (BFSI Sector/ StanChart, Max Life, Tata AIG, Birla Sunlife, Kotak Life) as Business Development, Operations & Strategy, Business mentor (Sales & Strategy)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>*/}
                    {/* Suxes Management Team Start */}
                </div>
            </React.Fragment >
        );
    }
}