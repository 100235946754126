import React from 'react';

import Header from './Component/Header';
import Footer from './Component/Footer';
import '../core/styles/_aquash.scss';
import Config from "../Config";
//import  '../../../src/core/styles/aquash.scss';
import Enquire from "./AquashEnquiryModal/AquashEnquiry"

export default class BusinessIndex extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            show:true
        };
    }
    handleShow=()=>{

        this.setState({show:true});
    }

    render() { 
       
        return (
            <React.Fragment>
                <Enquire show={this.state.show}></Enquire>
            <div className="aquash">
                <Header />
                    <div className="main">
                    <>
                        <section style={{ backgroundImage: 'linear-gradient(135.09deg, #6A5DD4 0%, #6058D1 100%)', padding: '60px 0px' }}>
                            <div className="container-fluid">
                                <figure>
                                    <img src={require("./image/Trolley.png")} className="main-img" />
                                </figure>
                                <h2 className="main-title">Paani pe Petrol ka Cashback</h2>
                                <p className="main-content">User can choose any one option ranging from Instant Cashback of ₹10/- or GG Movie
                                    vouchers worth ₹100/-
                                </p>
                                <div className="text-center mt-5"><button className="btn enquire-now" onClick={this.handleShow}>Enquire Now</button></div>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <div className="container">
                                <h2 className="heding-title">Business Potential</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12">
                                        <figure>
                                            <img src={require("./image/Business_potential.png")} className="business-img"  />
                                        </figure>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12">
                                        <div className="bg-dark-pink over">
                                            <p className="it-accounts-for-over">India ranks in the top 10 <b>largest bottled water consumers</b> in the world.</p>
                                        </div>
                                        <div className="bg-dark-blue over">
                                            <p className="it-accounts-for-over">The mineral water industry is a <b>symbol of a new lifestyle emerging in India.</b></p>
                                        </div>
                                        <div className="bg-dark-pink over">
                                            <p className="it-accounts-for-over">This market is growing at a <b>CAGR of 22%, is expected to continue its growth</b> momentum and grow many folds in the coming years.</p>
                                        </div>
                                        <div className="bg-dark-blue over">
                                            <p className="it-accounts-for-over">Indians are <b>currently spending about ₹23 billion</b> approx. a year on bottled water.</p>
                                        </div>
                                        <div className="bg-dark-pink over">
                                            <p className="it-accounts-for-over">Due to ease of availability of bottled water, the <b>per capita Consumption of bottled water in India</b> is on the increase.</p>
                                        </div>
                                        <div className="bg-dark-blue over">
                                            <p className="it-accounts-for-over">Packaged drinking water industry in India is currently valued at approx. <b>₹160 billion</b></p>
                                        </div>
                                        <div className="bg-dark-blue over">
                                            <p className="it-accounts-for-over">Indian bottled water industry will be booming in coming years and do not be surprised if <b>today’s bottled water industry becomes the next oil industry by 2025.</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <h2 className="heding-title">GG Fuel CashBack Model</h2>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12">
                                        <figure>
                                            <img src={require("./image/fuel_cashback_model_main.png")} />
                                        </figure>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12">
                                        <h2 className="title-model">Buy 1 Litre of Packaged Drinking Water</h2>
                                        <div className="text-center">
                                            <i className="fa fa-angle-down pt-1 pb-1" style={{ fontSize: 35 }} />
                                        </div>
                                        <h2 className="title-model" style={{color: "#4E5FFF"}}>Scan the QR Code</h2>
                                        <div className="text-center">
                                            <i className="fa fa-angle-down pt-1 pb-3" style={{ fontSize: 35 }} />
                                        </div>
                                        <div className="text-center">
                                            <p className="fual-caseback">1 Litre of Fuel Cashback/ <br />other Rewards</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <figure>
                                            <img src={require("./image/fuel_cashback_model.png")} className="business-img by-caseback-model" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <div className="container">
                                <div className="heding-title title">
                                    <h2 className>Fuel Cashback</h2>
                                    <p>How to get Fuel Cashback/Reward?</p>
                                </div>
                                <figure>
                                    <img src={require("./image/fuel_cashback.png")} />
                                </figure>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <div className="container">
                                <h2 className="heding-title">Cashback Options</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12 mb-5">
                                        <div className="ueser-bg-title">
                                            <figure>
                                                <img src={require("./image/fpv_my_circle.png")} className="mb-5" />
                                            </figure>
                                            <p className="my-circle">FPV My Circle</p>
                                            <div className="tagged-title mb-5">
                                                <p>User gets ₹1 lakh cashback/reward on purchase of 1 water bottle just by referring 6 users to generate FPVs under him, thus creating a circle by generating his own FPV at the beginning, thereafter referring 6 FPVs under his FPV .Further these 6 FPVs will refer other 6 FPVs under each FPV of their previous referral Circle and so on till it reaches the maximum level of 9 circles. This will continue for all FPVs till 9 referral circles</p>
                                            </div>
                                            <a href={Config.MAIN_APP_URL+'systematic-cashback?c=fpv'} target="_blank" rel="noopener noreferrer"className="view-more-btn">VIEW MORE</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12 mb-5">
                                        <div className="ueser-bg-title">
                                            <figure>
                                                <img src={require("./image/fpv_instant_cashback.png")} className="mb-5" />
                                            </figure>
                                            <p className="my-circle">FPV Instant Cashback/Reward</p>
                                            <div className="tagged-title mb-5">
                                                <p>Instant Cashback of ₹10/-</p>
                                                <p>OR</p>
                                                <p>(BOGO) Buy one Bottle & Get one Bottle absolutely Free</p>
                                                <p>OR</p>
                                                <p>Paytm Movie Voucher or Shopping Voucher worth ₹ 100</p>
                                                <p>OR</p>
                                                <p>Avail Nearby Offers</p>
                                            </div>
                                            <a href={Config.MAIN_APP_URL+'systematic-cashback?c=fpv'} target="_blank" rel="noopener noreferrer"className="view-more-btn">VIEW MORE</a>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12 mb-5">
                                        <div className="ueser-bg-title">
                                            <figure>
                                                <img src={require("./image/fpv_rpp.png")} className="mb-5" />
                                            </figure>
                                            <p className="my-circle"> FPV Random Pick Process</p>
                                            <div className="tagged-title mb-5">
                                                <p><b>2% to 100% Cashback of Fuel Value (in a month)</b></p>
                                                <p>The System will randomly select the user and cashback percentage generated in a month. Qualifiers are arranged in a pool and system will randomly select the FPV and cashback percentage. Unlike the above process of FIFO, RPP users will not have to wait for their turn, they are randomly selected & Paid in months</p>
                                            </div>
                                            <a href={Config.MAIN_APP_URL+'systematic-cashback?c=fpv'} target="_blank" rel="noopener noreferrer"className="view-more-btn">VIEW MORE</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12 mb-5">
                                        <div className="ueser-bg-title">
                                            <figure>
                                                <img src={require("./image/fpv_fifo.png")} className="mb-5" />
                                            </figure>
                                            <p className="my-circle">FPV First In First Out</p>
                                            <div className="tagged-title mb-5">
                                                <p><b>Get 2% of the fuel amount as Instant Cashback along with 100% Cashback in next 3 instalments </b></p>
                                                <p>A User who generates the FPV first will get the Cashback in 3 instalments when FPVs are tagged as per SCB tagging system along with conversion of GG Coins into GG Money.</p>
                                            </div>
                                            <a href={Config.MAIN_APP_URL+'systematic-cashback?c=fpv'} target="_blank" rel="noopener noreferrer"className="view-more-btn">VIEW MORE</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <div className="container">
                                <h2 className="heding-title">Why to choose GG Water Bottle ?</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div className="choose-product-left">
                                            <h2>Normal Water Bottle</h2>
                                            <figure>
                                                <img src={require("./image/water-botel.png")} className="water-botel" />
                                            </figure>
                                            <h3 className="benefits">No Benefits</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div className="choose-product-right">
                                            <h2>GG Water Bottle</h2>
                                            <figure>
                                                <img src={require("./image/water-blue.png")} className="water-botel-right" />
                                            </figure>
                                            <div className="benefits-right">
                                                <p>100 GG Coins* Instant</p>
                                                <p>₹100 GG Money + 2% Instant GG Money + 100 GG <br /> Coin Conversion to GG Money (Through SCB FIFO) </p>
                                                <p>Or</p>
                                                <p>₹ 1 Lakh GG Money on Purchase of one water bottle just by Referring 6 FPVS</p>
                                                <p>Or</p>
                                                <p>2% to 100% Cashback of Fuel value</p>
                                                <p>Or</p>
                                                <p>Instant Cashback of ₹10/- <br />
                                                (BOGO) Buy one Bottle & Get one Bottle absolutely Free <br />
                                                Paytm Movie Voucher or Shopping Voucher worth ₹100</p>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-5">
                                        <figure>
                                            <img src={require("./image/GG_money.png")} />
                                        </figure>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-5">
                                        <h2 className="gg-coin">GG Money</h2>
                                        <p className="gg-money">This can be immediately transferred to User's Bank <br />
                                        Account. Cashback/Reward through FPV <br />
                                        FIFO, FPV RPP, IC, My Circle and Referral <br />
                                        Cashback is given in the form of <br />
                                        GG Money.</p>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-5 col-sm-push-6">
                                        <figure>
                                            <img src={require("./image/GG-Coins.png")} />
                                        </figure>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-5 col-sm-pull-6">
                                        <h2 className="gg-coin">GG Coins</h2>
                                        <p className="gg-money">You Get Instant 100% GG Coins On Generation Of Each And Every UPV. <br />
                                        This Can Be Redeemed With New Purchases To The Extent Of 5% Of The Bill Amount And GG-Coins Which Remain In GG-Rewards Till 4th Cashback Payout of SCB FIFO Will Be Compulsorily Converted Into GG-Money maximum up to ₹ 10,000/- And Then You Can Encash It to your Bank Account*</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <div className="container">
                                <h2 className="heding-title">Growth Driver</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12 col-sm-12 col-12 mb-5">
                                        <div className="bg-dark-pink over">
                                            <p className="it-accounts-for-over">Burgeoning <b>Population, Rapid Urbanization</b></p>
                                        </div>
                                        <div className="bg-dark-blue over">
                                            <p className="it-accounts-for-over">Phenomenal <b>Increase in Tourism</b></p>
                                        </div>
                                        <div className="bg-dark-pink over">
                                            <p className="it-accounts-for-over"><b>Growing Awareness</b> about drinking water safety</p>
                                        </div>
                                        <div className="bg-dark-blue over">
                                            <p className="it-accounts-for-over">Poor public water <b>Distribution and Infrastructure</b></p>
                                        </div>
                                        <div className="bg-dark-pink over">
                                            <p className="it-accounts-for-over">Non availability of <b>Clean and Safe Drinking Water</b></p>
                                        </div>
                                        <div className="bg-dark-blue over">
                                            <p className="it-accounts-for-over"><b>80% of Diseases in India are Identifid as Water</b> borne diseases</p>
                                        </div>
                                        <div className="bg-dark-pink over">
                                            <p className="it-accounts-for-over">Scarcity of potable and wholesome water at <b>Stations, Parks, Tourist sports, etc</b></p>
                                        </div>
                                        <div className="bg-dark-blue over">
                                            <p className="it-accounts-for-over">Growing health consciousness, growing hygiene consciousness & increase in <b>Purchasing Power</b></p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12 col-sm-12 col-12 middle-text">
                                        <figure>
                                            <img src={require("./image/growth_driver.png")} />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <h2 className="heding-title">4 Types of GG CashAqua Franchise</h2>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12 col-md-12 middle-text">
                                        <figure>
                                            <img src={require("./image/4_types_aquash_franchise.png")} />
                                        </figure>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-12 middle-text">
                                        <div className="state-heading">
                                            <div>
                                                <p className="franchise" style={{ backgroundColor: '#4E5FFF' }}>CashAqua State Franchise</p>
                                            </div>
                                            <div>
                                                <p className="franchise" style={{ backgroundColor: '#8C8CFF' }}>CashAqua city Franchise</p>
                                            </div>
                                            <div>
                                                <p className="franchise" style={{ backgroundColor: '#835AF6' }}>CashAqua Pincode Franchise</p>
                                            </div>
                                            <div>
                                                <p className="franchise" style={{ backgroundColor: '#BA89FF' }}>One Water Trolley/ Shop-in-shop Franchise</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <div className="container">
                                <h2 className="heding-title">Process Flow</h2>
                                <figure>
                                    <img src={require("./image/flow.png")} />
                                </figure>
                                <p className="manufacturers">MANUFACTURERS WILL BE IN DIFFERENT STATES AND STOCKIST/ACP WILL BE IN ALL PIN CODES8.8</p>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <div className="container">
                                <h2 className="heding-title">Highlight of the Franchise</h2>
                                <figure>
                                    <img src={require("./image/highlight_of_the_franchise.png")} />
                                </figure>
                                <h2 className="by-caseback">Buy any Franchise and get 100% Cashback (through FPV FIFO) too, <br />
                                on Franchise Fee along with other benefits.</h2>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <div className="container">
                                <h2 className="heding-title mb-2">CashAqua State Franchise</h2>
                                <h3 className="gst">Franchise Fee: ₹18 Crore (+GST)</h3>
                                <h4 className="actual-franchise-fee">Actual Franchise fee will depend upon population & demography of the State</h4>
                                <figure>
                                    <img src={require("./image/state_franchise.png")} className="middle-text" />
                                </figure>
                                <h2 className="actual-franchise-fee">Franchise will be operated on FOCO Model</h2>
                                <p className="franchise-owned-com ">(Franchise Owned Company Operated)</p>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <div className="container">
                                <h2 className="heding-title mb-2">CashAqua City Franchise</h2>
                                <h3 className="gst"> Franchise Fee: ₹4.5 Crore (+GST)</h3>
                                <h4 className="actual-franchise-fee">Actual Franchise fee will depend upon population & demography of the city</h4>
                                <figure>
                                    <img src={require("./image/city_franchise.png")} className="middle-text" />
                                </figure>
                                <h2 className="actual-franchise-fee"> Franchise will be operated on FOCO Model</h2>
                                <p className="franchise-owned-com ">(Franchise Owned Company Operated)</p>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <div className="container">
                                <h2 className="heding-title mb-2">CashAqua Pincode Franchise</h2>
                                <h3 className="gst">Franchise Fee: ₹4.5 Lakh (+GST)</h3>
                                <h4 className="actual-franchise-fee">₹5 lakh stock to be maintained</h4>
                                <figure>
                                    <img src={require("./image/pincode_franchise.png")} className="middle-text" />
                                </figure>
                                <h2 className="actual-franchise-fee">50% off on Franchise Fee for ACP who has his own UPOS <br /> 100% off on Franchise Fee for ACP who is Share Holder of GG</h2>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <div className="container">
                                <h2 className="heding-title mb-2">GG Water Trolley Franchise</h2>
                                <h3 className="gst"> Franchise Fee: ₹5.4 Lakh (+GST)</h3>
                                <h4 className="actual-franchise-fee">Including e - Trolley + Deep Freezer, Free UPOS + Free Tablet/i pad, Free wifi</h4>
                                <figure>
                                    <img src={require("./image/water_trolley_franchise.png")} className="middle-text" />
                                </figure>
                                <h2 className="actual-franchise-fee" style={{ color: '#4E5FFF' }}>₹4 per Bottle Sold by Trolley (FOFO or FOCO Respectively)</h2>
                                <h3 className="upos-commission">10% of Advertisement Revenue Generated From Trolley (Sourced by Company)</h3>
                                <h3 className="upos-commission">(+ UPOS Commission Income)</h3>
                            </div>
                        </section>
                        <section className="section-bgs">
                            <h2 className="heding-title">Water Trolley Varient</h2>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6 col-xs-12 mb-5">
                                        <figure>
                                            <img src={require("./image/2per.png")} />
                                        </figure>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-xs-12 mb-5">
                                        <li className="upos-income">25% of the Trolley UPOS income</li>
                                        <li className="upos-income">25% of advertisement revenue generated from trolley, Sourced by Franchisee</li>
                                        <p className="all-financial-oper">All financial & operational expenses will be borne by the Company (Global Garner) along with the management of trolley excluding any major expenses in Trolley like Battery etc.</p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-xs-12 mb-5">
                                        <figure>
                                            <img src={require("./image/4per.png")} />
                                        </figure>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-xs-12 mb-5">
                                        <li className="upos-income">75% of the Trolley UPOS income</li>
                                        <li className="upos-income">75% of advertisement revenue generated from trolley, Sourced by Franchisee</li>
                                        <p className="all-financial-oper">All financial & operational expenses will be borne by the franchise Owner along with the management of trolley</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-bgs" style={{ backgroundColor: '#835AF6', backgroundImage: 'none' }}>
                            <div className="content">
                                <h2 className="missions-create">Mission to Create</h2>
                                <h3 className="entrepreneurs">21,000 Entrepreneurs 36,00,000 Digitally Empowered Vendors 72,00,000 Sustainable Jobs</h3>
                                <figure>
                                    <img src={require("./image/GRAB_YOUR_FRANCHISE.png")} />
                                </figure>
                                <div className="text-center mt-5"><button className="btn enquire-now" onClick={this.handleShow}>Enquire Now</button></div>
                            </div>
                        </section>
                       

                    </>

                    <Footer />
                </div>
            </div>
            </React.Fragment>
        );
    }
}