import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class CheifMentor extends Component{
    constructor(props){
        super(props);
        this.state={
            roles:"",
            mcp:false,
            cp:false,
            acp:false,
            partner:false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            roles:nextProps.busicalc
        })
    }

    render(){
        if(this.state.roles==="mcp"){
            return(
                <div className="cheifWrapper">
                    <h3>Roles and Responsibility of MCP</h3>
                    <ol className="calc_list">
                        <li>MCP is going to be the Non Exclusive Head of the sale of GG business in
                            State
                        </li>

                        <li>MCP will recruit a 10 CP for Multi Pincodes in his State
                        </li>
                        <li>Must have an office equipped with a meeting room for at least 45 people
                        </li>

                        <li>6 Dedicated staff for training and support
                        </li>
                        <li>Computer with high speed internet connection + Printer /Scanner +
                            Projector / Smart TV
                        </li>

                        <li>MCP will plan for marketing and advertisement in his state.
                        </li>
                        <li>
                            MCP has to arrange 4 meetings in a month for CP/ACP also he should be
                            ready to undergo any Training Program arranged by the company or LHO at
                            personal cost

                        </li>
                        <li>MCP will have to report his daily activities, target, achievements to
                            his appointed LHO
                        </li>

                        <li>MCP would keep a keen watch on all activities in his state & conduct
                            audits of CP/ACP & vendors from time to time.
                        </li>

                        <li><b>ONLY MCP can grow to become BP and with same Job role now he can
                            recruit his team PAN INDIA
                        </b></li>

                        <li><b>BP</b> would be eligible for 10% of MCP income Recruited by
                            him
                        </li>
                        <li><b>Note:</b> MCP has to be ready to spend Rs. 2 lakhs monthly to develop
                            his area and manage all GG working i.e. promotional activities,
                            marketing, training and advertisement
                        </li>

                        <li>Fee : 10 Lakh + GST
                        </li>
                        <li>Fee Refundable Clause : Sell 60 UPOS in Next 2 Months of Joining.
                        </li>
                    </ol>
                    <h4>Disclaimer</h4>
                    <ol className="disclaimer">
                        <li>Please note still we have not included Product ,Franchise, Cashback and Referral income So actual earning will be on a higher side.</li>
                        <li>Above given is potential income so please note actual income may vary as per the Performance by partner / advisor / vendor and UPOS.</li>
                        <li>This is expected income post 12 months once you setup your team. To know more please call 8756112227.</li>
                        <li>Above calculations are for illustration purpose only and we have considered a team of 10 people under each position.</li>
                    </ol>
                    <Link to={'/channel-partner'} className="btn btn-link">Know More</Link>
                </div>
            );
        }else if(this.state.roles==="cp"){
            return(
                <div className="cheifWrapper">
                    <h3>Roles and Responsibility of CP</h3>
                    <ol className="calc_list">

                        <li>CP is going to be the Non Exclusive Head of the sale of GG business in
                            assigned Pincodes(Multi)
                        </li>
                        <li>CP will recruit a 10 ACP for Pincodes in his district</li>
                        <li>Must have an office equipped with a meeting room for at least 25
                            people
                        </li>
                        <li>4 Dedicated staff for training and support</li>
                        <li>Computer with high speed internet connection + Printer /Scanner +
                            Projector / Smart TV
                        </li>
                        <li>CP will plan for marketing and advertisement in your District
                        </li>
                        <li>CP has to arrange 4 meetings in a month for ACP and Vendors also he
                            should be ready to undergo any Training Program arranged by the company
                            or MCP at personal cost
                        </li>

                        <li>CP will report to MCP on weekly basis on a prescribed format
                        </li>
                        <li>CP will have to keep watch on his entire district working through his
                            ACPs. This will include vendor and ACP audit also.
                        </li>

                        <li>CP will have to take care of ACP, Vendors, Users escalations in given
                            territory
                        </li>

                        <li>CP will have to appoint ACPs on each Pin Code of his allotted territory
                        </li>

                        <li><b>Note:</b> CP has to be ready to spend Rs. 1 lakh monthly to develop
                            his area and manage all GG working i.e. promotional activities,
                            marketing, training and advertisement
                        </li>

                        <li>Fee : 5 Lakh + GST
                        </li>
                        <li>Fee Refundable Clause : Sell 25 UPOS in Next 2 Months of Joining.

                        </li>
                    </ol>
                    <h4>Disclaimer</h4>
                    <ol className="disclaimer">
                        <li>Please note still we have not included Product ,Franchise, Cashback and Referral income So actual earning will be on a higher side.</li>
                        <li>Above given is potential income so please note actual income may vary as per the Performance by partner / advisor / vendor and UPOS.</li>
                        <li>This is expected income post 12 months once you setup your team. To know more please call 8756112227.</li>
                        <li>Above calculations are for illustration purpose only and we have considered a team of 10 people under each position.</li>
                    </ol>
                    <Link to={'/channel-partner'} className="btn btn-link">Know More</Link>
                </div>
            );
        }else if(this.state.roles==="acp"){
            return(
                <div className="cheifWrapper">
                    <h3>Roles and Responsibility of ACP</h3>
                    <ol className="calc_list">
                        <li>ACP is going to be the Exclusive Head of the particular pin code of GG
                            business
                        </li>
                        <li>ACP will recruit minimum 180 vendors in 6 months of his appointment of
                            his pin code
                        </li>
                        <li>ACP can recruit unlimited Partners under him across India.
                        </li>
                        <li>ACP can grown within system
                        </li>
                        <li>Must have an office equipped with a meeting room for at least 15
                            people
                        </li>
                        <li>2 Dedicated staff for training and support
                        </li>

                        <li>Computer with high speed internet connection + Printer /Scanner +
                            Projector / Smart TV
                        </li>
                        <li>ACP will plan for marketing and advertisement in his pin code
                        </li>
                        <li>ACP has to arrange 4 meetings in a month for Vendors also he should be
                            ready to undergo any Training Program arranged by the company or CP-MCP
                            at personal cost
                        </li>
                        <li>ACP will have to report to his CP on weekly basis on a prescribed format
                        </li>
                        <li>ACP would keep a keen watch on all activities in his pin code & conduct
                            audits in his area time to time.
                        </li>
                        <li>ACP will have to take care of Vendors and Users escalations in your
                            given territory
                        </li>
                        <li><b>Note:</b> ACP has to be ready to spend Rs. 50000 monthly to develop
                            his area and manage all GG working i.e. promotional activities,
                            marketing, training and advertisement
                        </li>
                        <li>Fee : 2 Lakh + GST
                        </li>
                        <li>Fee Refundable Clause : Sell 6 UPOS in Next 2 Months of Joining.
                        </li>
                        <li>If unable to achieve 6 UPOS in 2 Months
                        </li>
                        <li>Firstly, post June he will again get 2 Month time to complete his target
                            of 6 + 3 New UPOS If even after 4 month he is unable to sell 9 UPOS then
                            he will be demoted as Partner. Minimum Target to Retain ACP Location
                            would be 2 UPOS per Month.
                        </li>
                        <li>For Permanent Posting 150 UPOS or 5 Years Whichever happens earlier Also
                            as MCP-CP are non-exclusive post so there would be no timeline for them.
                        </li>
                    </ol>
                    <h4>Disclaimer</h4>
                    <ol className="disclaimer">
                        <li>Please note still we have not included Product ,Franchise, Cashback and Referral income So actual earning will be on a higher side.</li>
                        <li>Above given is potential income so please note actual income may vary as per the Performance by partner / advisor / vendor and UPOS.</li>
                        <li>This is expected income post 12 months once you setup your team. To know more please call 8756112227.</li>
                        <li>Above calculations are for illustration purpose only and we have considered a team of 10 people under each position.</li>
                    </ol>
                    <Link to={'/channel-partner'} className="btn btn-link">Know More</Link>
                </div>
            );
        }else if(this.state.roles==="partner"){
            return (
                <div className="cheifWrapper">
                    <h3>Roles and Responsibility of Partner</h3>
                    <ol className="calc_list">
                        <li>Partner is going to be the appointed by ACP and Will report into ACP
                        </li>
                        <li>Partner can be any person who is willing to sell UPOS and other Products
                            & Services of GG
                        </li>
                        <li>Should be ready to undergo any Training Program arranged by the company
                            or ACP-CP-MCP at personal cost
                        </li>
                        <li>Should be ready to undergo any Training Program arranged by the company
                            or ACP-CP-MCP at personal cost
                        </li>
                        <li>Should have good network like Insurance Agents, MF Agents etc.
                        </li>
                        <li>Sell 60 UPOS to become ACP in Partner Channel
                        </li>
                        <li>Apart from incentive Partner would also be eligible for Performance
                            Based Salary
                        </li>

                    </ol>
                    <h4>Disclaimer</h4>
                    <ol className="disclaimer">
                        <li>Please note still we have not included Product ,Franchise, Cashback and Referral income So actual earning will be on a higher side.</li>
                        <li>Above given is potential income so please note actual income may vary as per the Performance by partner / advisor / vendor and UPOS.</li>
                        <li>This is expected income post 12 months once you setup your team. To know more please call 8756112227.</li>
                        <li>Above calculations are for illustration purpose only and we have considered a team of 10 people under each position.</li>
                    </ol>
                    <Link to={'/channel-partner'} className="btn btn-link">Know More</Link>
                </div>
            );
        }else if(this.state.roles==="chief_mentor"){
            return (
                <div className="cheifWrapper">
                    <h3>Chief Mentor Roles & Responsibilities</h3>
                    <ol>
                        <li>Has to sell UPOS and other products & services of Global Garner through its team of Mentor, Consultant & advisers.</li>
                        <li>Should have large network of People.</li>
                        <li>Can appoint as many Mentors he wants.</li>
                        <li>Has to follow up with Mentors & his team for the selling of UPOS & other Products.</li>
                    </ol>
                    <h4>Disclaimer</h4>
                    <ol className="disclaimer">
                        <li>Please note still we have not included Product ,Franchise, Cashback and Referral income So actual earning will be on a higher side.</li>
                        <li>Above given is potential income so please note actual income may vary as per the Performance by partner / advisor / vendor and UPOS.</li>
                        <li>This is expected income post 12 months once you setup your team. To know more please call 8756112227.</li>
                        <li>Above calculations are for illustration purpose only and we have considered a team of 10 people under each position.</li>
                    </ol>
                    <Link to={'/channel-partner'} className="btn btn-link">Know More</Link>
                </div>
            );
        }else if(this.state.roles==="mentor"){
            return (
                <div className="cheifWrapper">
                    <h3>Mentor Roles & Responsibilities</h3>
                    <ol className="calc_list">
                        <li>Has to sell UPOS and other products & services of Global Garner through
                            its team of Consultant & advisers
                        </li>
                        <li>Should have large network of People
                        </li>
                        <li>Can appoint as many Consultant he wants
                        </li>
                        <li>Has to follow up with Consultant & his team for the selling of UPOS &
                            other Products
                        </li>
                        <li>Can be promoted as Chief Mentor on achievement of target
                        </li>
                    </ol>
                    <h4>Disclaimer</h4>
                    <ol className="disclaimer">
                        <li>Please note still we have not included Product ,Franchise, Cashback and Referral income So actual earning will be on a higher side.</li>
                        <li>Above given is potential income so please note actual income may vary as per the Performance by partner / advisor / vendor and UPOS.</li>
                        <li>This is expected income post 12 months once you setup your team. To know more please call 8756112227.</li>
                        <li>Above calculations are for illustration purpose only and we have considered a team of 10 people under each position.</li>
                    </ol>
                    <Link to={'/channel-partner'} className="btn btn-link">Know More</Link>
                </div>
            );
        }else if(this.state.roles==="consultant"){
            return(
                <div className="cheifWrapper">
                    <h3>Consultant Roles & Responsibilities</h3>
                    <ol className="calc_list">
                        <li>Has to sell UPOS and other products & services of Global Garner through
                            its team of advisers
                        </li>
                        <li>Should have good network like Insurance Agents, MF Agents etc.
                        </li>
                        <li>Can appoint as many advisers he wants
                        </li>
                        <li>Has to follow up with advisers for the selling of UPOS & other Products
                        </li>
                        <li>Can be promoted as Mentor on achievement of target
                        </li>
                    </ol>
                    <h4>Disclaimer</h4>
                    <ol className="disclaimer">
                        <li>Please note still we have not included Product ,Franchise, Cashback and Referral income So actual earning will be on a higher side.</li>
                        <li>Above given is potential income so please note actual income may vary as per the Performance by partner / advisor / vendor and UPOS.</li>
                        <li>This is expected income post 12 months once you setup your team. To know more please call 8756112227.</li>
                        <li>Above calculations are for illustration purpose only and we have considered a team of 10 people under each position.</li>
                    </ol>
                    <Link to={'/channel-partner'} className="btn btn-link">Know More</Link>
                </div>
            );
        }else if(this.state.roles==="adviser"){
            return(
                <div className="cheifWrapper">
                    <h3>Adviser Roles & Responsibilities</h3>
                    <ol className="calc_list">
                        <li>Has to sell UPOS and other products & services of Global Garner
                        </li>
                        <li>Should have good network like Insurance Agents, MF Agents etc.
                        </li>
                        <li>Can be promoted to Consultant on achievement of target
                        </li>
                    </ol>
                    <h4>Disclaimer</h4>
                    <ol className="disclaimer">
                        <li>Please note still we have not included Product ,Franchise, Cashback and Referral income So actual earning will be on a higher side.</li>
                        <li>Above given is potential income so please note actual income may vary as per the Performance by partner / advisor / vendor and UPOS.</li>
                        <li>This is expected income post 12 months once you setup your team. To know more please call 8756112227.</li>
                        <li>Above calculations are for illustration purpose only and we have considered a team of 10 people under each position.</li>
                    </ol>
                    <Link to={'/channel-partner'} className="btn btn-link">Know More</Link>
                </div>
            );
        }

        return(
            <React.Fragment>
                <div className="cheifWrapper">
                    <h4>Disclaimer</h4>
                    <ol className="disclaimer">
                        <li>Please note still we have not included Product ,Franchise, Cashback and Referral income So actual earning will be on a higher side.</li>
                        <li>Above given is potential income so please note actual income may vary as per the Performance by partner / advisor / vendor and UPOS.</li>
                        <li>This is expected income post 12 months once you setup your team. To know more please call 8756112227.</li>
                        <li>Above calculations are for illustration purpose only and we have considered a team of 10 people under each position.</li>
                    </ol>
                    <Link to={'/channel-partner'} className="btn btn-link">Know More</Link>
                </div>
            </React.Fragment>
        )
    }
}
