import React from 'react';
import YouTubePlayer from 'react-youtube';

export default class Youtube extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            videoId: props.videoId ? props.videoId : "gEUp7ITlJSg"
        };
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.playVideo();
    }

    render() {
        const opts = {
            height: '365',
            width: '560',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: '0'
            }
        };

        return (
            <YouTubePlayer
                videoId={this.state.videoId}
                opts={opts}
                onEnd={this.props.onEnd}
                onReady={this._onReady}
            />
        );
    }
}
