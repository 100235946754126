import React from 'react';
//import Config from '../../Config'
import {Link} from "react-router-dom";

export default class Water extends React.Component {
    render(){
        return(
            <React.Fragment>
                <div className="title-box mb-4">
                    <div className="col-12 text-center">
                        <h1 className="bp3-text-xlarge heading mb-3 mt-3">GG Aquash</h1>
                        <p className="mt-3 mb-0">Register to access and to have real-time status of your trolley<br></br>
                            You have to mandatorily Register as a User First. {/*<a href={Config.WATER_TROLLEY + 'dashboard'} rel="noopener noreferrer" target="_blank">Login for Water Trolley And Trolley Manager</a>*/}</p>
                    </div>
                </div>

                <div className="row col-12 px-s-0 justify-content-center">
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/water-trolley" className="bp3-button bp3-large waterTrolley pr-5 pl-5 w-100">Water Trolley</Link>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/trolley-manager" className="bp3-button bp3-large trolleyManager pr-5 pl-5 w-100">Trolley Manager</Link>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <Link to="/trolley-pilot" className="bp3-button bp3-large trolleyPilot pr-5 pl-5 w-100">Trolley Pilot</Link>
                    </div>
                </div>

                {/*<div className="row col-12 px-s-0 justify-content-center">
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.WATER_TROLLEY + 'register/2'} className="bp3-button bp3-large waterTrolley pr-5 pl-5 w-100" rel="noopener noreferrer" target="_blank">Water Trolley</a>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.WATER_TROLLEY + 'register/3'} className="bp3-button bp3-large trolleyManager pr-5 pl-5 w-100" rel="noopener noreferrer" target="_blank">Trolley Manager</a>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.WATER_TROLLEY + 'register/1'} className="bp3-button bp3-large trolleyPilot pr-5 pl-5 w-100" rel="noopener noreferrer" target="_blank">Trolley Pilot</a>
                    </div>
                </div>*/}



            </React.Fragment>
        );
    }
}