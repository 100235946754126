import React from 'react';

import  '../../../src/core/styles/_aquash.scss';

export default class Business extends React.Component {


  render() {

    return (
      <React.Fragment>
        <footer>
            <div className="container">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                    <h3 className="contact-us">Contact us</h3>
                    <p className="address">Head Office : 5th floor, Grand Emporio, Motera Stadium Road, Ahmedabad, Gujarat 380005</p>
                    <p className="address">For Franchise Inquiry contact : +91 63549 17501</p>
                </div>
            </div>
        </footer>
        <p className="copyrights">Copyright © 2017-2023. All rights reserved.</p>
      </React.Fragment>
    )
  }
}
