import React from 'react';
import {Header, Footer, Auth, ScrollTop} from 'gg-react-utilities';

export default class HeaderFooter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isHeader:'Web',
        }
    }

    componentDidMount(){

        setTimeout(async ()=>{
            let isHeader = 'Web';
            if (typeof getDeviceName == 'function' && window['getDeviceName'] !== undefined){
                await Auth.update();
                isHeader = window.getDeviceName();
            }else if(typeof Android !== "undefined" && window['Android'] !== null) {
                await Auth.update();
                isHeader = window.Android.getDeviceName();
            }

            this.setState({
                isHeader:isHeader
            })
        },50)

    }

    render(){
        return (
            <React.Fragment>
                {
                this.state.isHeader===undefined && this.state.isHeader==='Web'?
                        <Header headerType="dark" />
                        :
                        ''
                }

                {this.props.children}
                <ScrollTop/>
                {
             this.state.isHeader===undefined && this.state.isHeader==='Web'?
                        <Footer/>
                        :
                        ''
                }
            </React.Fragment>
        )
    }
}
