import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import {PackageConfigure} from 'gg-react-utilities';
import {packageConfigure} from './Config';

ReactDOM.render(<React.Fragment>
    <App/>
    <PackageConfigure {...packageConfigure} />
</React.Fragment>, document.getElementById('root'));
unregisterServiceWorker();
