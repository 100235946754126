import React from 'react'
import { Route } from 'react-router-dom'
import {Header, Footer, ScrollTop } from 'gg-react-utilities';

const PrivateRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={(props) => (
        <div className="business">
            {window.location != '/grange'||window.location != '/aquash' ?
            <Header />:""}
            <Component {...props} />
            <ScrollTop />
            {window.location!=='/grange'||window.location != '/aquash'?<Footer />:""}
        </div>
    )}/>
);

export default PrivateRoute;
