import React from 'react';
import BillImage from '../../core/images/png/bill_pay.png'
import Config from "../../Config";

export default class Business extends React.Component {
  constructor(props){
      super(props);
        this.state = {
          data:[

            {
              title:'Get Digitalization Package worth ₹ 50,000/- absolutely FREE',
              image:require('../images/png/Digitalization.png'),
              desc:'Get all your products and services online with our Unique Digital Package worth ₹ 50,000, absolutely FREE and manage everything through our website and Vendor mobile app. We offer a hassle-free platform to build your retail business and manage every aspect of your business so that you can focus on your core operational job. Gradually, the purchasing pattern has changed and will change further at a fundamental level. Customers will explore for more alternatives to where and how to buy: offline or online etc. It is the best time to encash this opportunity free of cost from us. So, don’t miss the chance to be right at the center of the digital world.'
            },
            {
              title:'Exclusive Store',
              image:require('../images/png/exclusive_store.png'),
              desc:'You get EXCLUSIVITY for 1 Kilometre radius. Unlike others, we don’t create your competition in your area. We give an exclusive right of 1 KM to you depending upon the volume of sales routed through us. Customers will come to you because they will get 2% to 100% Cashback/Reward through 3 copyrighted cashback process from Global Garner which is the best deal any vendor can offer. So, you can create your brand value in your vicinity.'
            },
            {
              title:'Free On-Ground Sales Support',
              image:require('../images/png/free-on_ground_support.png'),
              desc:'Not only online and digital support, but we are also your personal salesman for on-ground support, that too free of "fixed cost". You have to pay only when we sell for you, that too post-sales commission only. As we are post-paid sales service provider, we can also customize sales solutions as per your requirements, both Online and Offline. We are operational in all major states of the country so you can have your sales team nation-wide with zero upfront cost through our On-Ground Sales Support & UPOS Partners. So, we absorb the burden of sales salary from you.'
            },
            {
              title:'Pay on Sale Only',
              image:require('../images/png/Pay-On-Sale.png'),
              desc:'As we are the post-paid sales service provider and believe in real sales, we charge agreed-on commission only, once we deliver what we promised i.e. absolute & real sales for your business. Otherwise, you need not to pay anything for listing, marketing, digital package, sales support etc. that we have done for your business. We do not seek any upfront or pre-paid commission for our services. So, no financial risk involved.'
            },
          ]
        }
  }

    render() {

        return(
            <React.Fragment>
                <div className="businessWrapper mb-5">
                  <div className="calcForm py-5">
                    <div className="container row px-s-0">
                      <div className="col-12 text-center">
                        <h3>Global Garner is World’s 1st and Only Post-paid Sales Service Provider</h3>
                        <img src={require('../images/png/world-first.png')} alt="" className="img-fluid mt-4" />
                      </div>
                      <div className="col-12 mt-5">
                        <p>We are World’s 1st and Only Post-paid Sales service provider for all category of vendors at Zero Upfront Cost, which helps you to sell all your products and services with our Advanced Digital Platform and On-Ground Sales Support and UPOS partners.
                        <span> We also provide the best offer to your customers of 2% to 100% cashback/Reward with No Upper Limit which makes us the first choice for any vendor as we first perform and then seek for our commission only, Nothing in advance. As every Business requires Sales, not only Marketing, so we act as your Personal Salesman and will endeavour to
                          generate absolute sales and subsequently seek for Commission, unlike other platforms who seek high up-front fee before delivering anything and even after paying their fee, you never get the assurance of sales. We charge agreed-on commission only, once we deliver what we promised i.e. absolute & real sales for your business.</span>
                        <br></br>
                        So, it’s almost Like having your Sales Team across India with ZERO UPFRONT COST.</p>
                      </div>
                    </div>
                  </div>
                  {this.state.data.map((res,i)=>{
                    let styleClass = i%2===0? 'calcForm py-5 Digitalization' :'calcForm py-5 Exclusive'
                    return(
                      <div className={styleClass}>
                        {i%2===0?
                            <div className="container">
                              <h3>{res.title}</h3>
                              <div className="row d-flex flex-column-reverse flex-md-row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center">
                                    <p>{res.desc}</p>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center d-flex align-items-center">
                                    <img src={res.image} alt="" className="img-fluid mt-5 mt-s-0 mb-s-4" />
                                </div>
                              </div>
                            </div>
                            :
                            <div className="container">
                              <h3>{res.title}</h3>
                              <div className="row px-s-0 d-flex flex-lg-row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center d-flex align-items-center">
                                  <img src={res.image} alt="" className="img-fluid mt-5 mt-s-0 mb-s-4" />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center">
                                  <p>{res.desc}</p>
                                </div>
                              </div>
                            </div>
                      }
                      </div>
                    )
                  })}

                    <div className="calcForm py-5 Digitalization">
                      <div className="container">
                        <h3>Give Guaranteed cashback/reward of 2%-100% to your customer</h3>
                        <div className="row d-flex flex-column-reverse flex-md-row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center flex-column justify-content-center">
                            <p>Give guaranteed 2% to 100% cashback/reward to all your customers through 3 copyrighted cashback process offered by us, which customer can transfer to their bank account. If they can wait more offer them the option of 100% cashback in 3 unequal installments. Apart from all the benefits of a vendor, we also offer cashback to your customers from our side, without burning a hole in your pockets. So, this is the exclusive ad on benefit we offer to you.</p>
                            <h5>(Note: 2% is in the form of gift vouchers and other cashback will be in the form of real money which can be transferred to Bank account)</h5>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center d-flex align-items-center">
                              <img src={require('../images/png/Give-Cashback.png')} alt="" className="img-fluid mt-5 mt-s-0 mb-s-4" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="calcForm py-5">
                      <div className="container row px-s-0">
                        <div className="col-12 text-center">
                          <h3 className='mb-1'>Universal Point of Sale</h3>
                          <h4 className='text-muted m-0'>“Ab sabka Business hai apna Business”</h4>
                          <img src={require('../images/png/UPOS.png')} alt="" className="img-fluid mt-5 mt-s-4 mb-s-0" />
                        </div>
                        <div className="col-12 mt-5">
                          <p>Now you can engage your current customers base to Generate new revenue and Sell 20 Crore + products and services to your customers with our advanced digital revolution.</p>
                          <p className='text-muted'>
                            Your customer is also a consumer of many other product and services which you don’t offer but now with UPOS serve
                            them with these 20crore + products and services and earn, at NO COST without investing in inventory. Also, Give
                            Guaranteed cashback/reward of 2% -100% to your customers on these 20 crore + products and services which customer
                            can transfer to their bank account which is over and above of what your customer can get by going directly to that
                            particular brand. Sell most diversified inventory, help people to select and buy through UPOS and earn incentive on
                            all their purchases.</p><br/>
                          <p>Get leveraged by our Distribution network for your sales Our Distributors are in 3 Forms, who
                            can assist you to create sales : </p>
                          <ol>
                            <li>UPOS Partner: Who Buys our UPOS(digital software) to resell the brand and services offered by us.</li>
                            <li>Channel Partner /Mentor: Sales force from a different segment of society working as freelancers who work for us to promote.</li>
                            <li>User App (Both Android & IOS): As we already have a large number of Users on our platform, we can directly pitch the product/Services to them.</li>
                          </ol>
                          <br></br>
        				  <span><a href={Config.UPOS} rel="noopener noreferrer" target="_blank">Learn More about UPOS : Universal Point of Sale ></a></span>
                        </div>
                      </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
