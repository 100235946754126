import React from 'react';
import Config from "../../Config";


export default class MCP extends React.Component {
    render(){
        return(
            <React.Fragment>
                <div className="title-box mb-4">
                    <div className="col-12 text-center">
                        <h1 className="bp3-text-xlarge heading mb-3 mt-3">MCP Login & Register</h1>
                        <p className="mt-3 mb-0">Register as Master Channel Partner and create your own team to earn huge incentives<br></br>
                            <small>Before registering as MCP, you have to mandatorily register as a User First</small></p>
                    </div>
                </div>
                <div className="row col-12 px-s-0 justify-content-center">
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.MCP+ 'login/mcp'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large waterTrolley pr-5 pl-5 w-100">MCP Login</a>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 mt-5 mt-s-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                        <a href={Config.MCP+ 'mcp-register'} rel="noopener noreferrer" target="_blank" className="bp3-button bp3-large trolleyManager pr-5 pl-5 w-100">MCP Register</a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}