import React from 'react';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import { withRouter } from 'react-router-dom';
import sc_logo from '../images/png/GSDC0.png'

export class TopBar extends React.Component {
    render(){
        return(
            <div className="top-bar d-flex align-items-center justify-content-start">
                <span className="back-icon"  onClick={() => this.props.history.goBack()}><KeyboardArrowLeftRounded /></span>
                <span className="title">{this.props.title}</span>
                {
                    this.props.suxesHeader ?
                        <div className='nav_logo'>
                            <img src={sc_logo}/>
                        </div>
                    :''
                }
            </div>
        );
    }
}

export default withRouter(TopBar);