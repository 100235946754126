import { combineReducers } from 'redux';
//import { persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';


import { reducer as formReducer } from 'redux-form'

const recharge = combineReducers({
    form: formReducer
})

export default recharge;