import { ssoInstance, autoInstance, Auth } from 'gg-react-utilities';
import Config from '../../Config';

//GrangeInquiry
export  const  grangeInquiry = (data) => {
    return ssoInstance.post(Config.INQUIRY_APP_URL + 'api/v1/inquiry/grange',data)
}


export const  aquashInquiry = (data) => {
    return ssoInstance.post(Config.INQUIRY_APP_URL + 'api/v1/inquiry/aquash',data)
}