import React from 'react'
import Enquire from "../EnquiryModal/Enquiry";
import  '../../../src/core/styles/_grange.scss';

export default class Business extends React.Component {
  constructor(props){

    super(props);

    this.state = {
        show:false
    };

  }

   handleShow = () =>{
     this.setState({show:true});
  }


  render() {

    return (
      <React.Fragment>
             <Enquire show={this.state.show} />
         
        <footer className="footers">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-12 col-md-12 col-xs-12 ">
                <figure>
                  <img src={require('../image/grange_logo.png')} className="footers-logo" />
                </figure>
              </div>
              <div className="col-lg-8 col-sm-12 col-md-12 col-xs-12 ">
                <h2 className="mission-to-create">Mission to Create</h2>
                <h3 className="entrepreneurs">21,000 Entrepreneurs 36,00,000 Digitally Empowered <br /> Vendors 72,00,000 Sustainable Jobs</h3>
                <div className="text-center"><button className="btn enquire-now" onClick={this.handleShow}>Enquire Now</button></div>
              </div>
              <div className="hr-border" />
              <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                <h3 className="contact-us">Contact us</h3>
                <p className="address">Head Office : Hall C, 3rd Floor, 4D Square Mall, Visat - Gandhinagar Highway, opp. IIT, Motera, Ahmedabad, Gujarat 380005</p>
                <p className="address">For Franchise Inquiry contact : +91 63549 17509</p>
              </div>
            </div>
          </div>
        </footer>
        <p className="copyrights">Copyright © 2017-2021. All rights reserved.</p>
      </React.Fragment>
    )
  }
}
