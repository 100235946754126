import React from 'react';

import Header from './Component/Header';
import Footer from './Component/Footer';
import '../../src/core/styles/_grange.scss';
import Config from "../Config";
import Enquire from "./EnquiryModal/Enquiry"
import { Auth, NoLogin } from "gg-react-utilities";
export default class BusinessIndex extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            isLoggedIn: false,
        };
    }


    handleShow = () => {
        this.checkAuth()
        if (this.state.isLoggedIn) { this.setState({ show: true }) }
    }
    async checkAuth() {
        Auth.isLoggedInAsync().then((res) => {
            this.setState({ isLoggedIn: res }, () => {
                if (!res) {
                    Auth.login(true);
                }
            })
        });
    }
    render() {
        return (

            <React.Fragment>
                <Enquire show={this.state.show} />

                <div className="grange">

                    <Header />
                    <div className="main">


                        <section>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 p-0">
                                        <img src={require('./image/bg_img.png')} className="w-100 h-100" />
                                    </div>
                                    <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 bg-orange banner-space pt-3" >
                                        <div className="text-center text-white grange-family">
                                            <figure>
                                                <img src={require('./image/grange_logo.png')} className="grange-logo" />
                                            </figure>
                                            <p className="logo-text mb-4 pb-3">
                                                <img src={require('./image/GG.png')} /> &nbsp;
                                             A GLOBAL GARNER VENTURE</p>
                                            <h1 className="store-title">Family Fresh Kirana Store &amp; UPOS Store</h1>
                                            <h2 className="store-subtitle">with 4 Smart Cashback options on all Grocery and Digital Mall shopping.</h2>
                                            <h2 className="store-subtitle">You can also open your own Grocery Store or upgrade your existing one and offer Smart cashback to all your customers with GRANGE FRANCHISE</h2>
                                            <div><button className="btn enquire-now" onClick={this.handleShow}>Enquire Now</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-bg">
                            <div className="container">
                                <h2 className="heding-title">What is GG Grange?</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                        <figure>
                                            <img src={require('./image/deliveryboy.png')} />
                                        </figure>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                        <p className="grange-title">GG Grange is a Grocery Mart with a fusion of brick and mortar model and online shopping, having a wide assortment of Food, Vegetables, Beverages and Household item integrated with digital UPOS Mall of 20 crore + Products and Services with exclusive cashback options up to ₹1 crore.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-bg">
                            <div className="container">
                                <h2 className="heding-title">Highlights of Grocery Business in India</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                        <figure>
                                            <img src={require('./image/tolly.png')} />
                                        </figure>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                        <p class="important-facts">Few Important Facts</p>
                                        <p className="rate-title">India is the sixth-largest grocery market in the world. The Indian grocery retail market, currently estimated at $343 billion, is projected to grow at a compounded annual growth rate of 6% to reach $610 billion by 2023</p>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                        <div className="fact-menu">
                                            <div className="bg-orange over">
                                                <p className="it-accounts-for-over">It accounts for over 16% of the country’s Gross Domestic Product (GDP) and around 8% of the employment</p>
                                            </div>
                                            <div className="bg-red over">
                                                <p className="it-accounts-for-over">It has massive potential for growth due to the rising population and disposable income</p>
                                            </div>
                                            <div className="bg-orange over">
                                                <p className="it-accounts-for-over">The Indian grocery market accounts for 69% of India's total retail market</p>
                                            </div>
                                            <div className="bg-red over">
                                                <p className="it-accounts-for-over">The online grocery market is growing at an estimated rate of 25-30% annually in metropolitan areas and large cities</p>
                                            </div>
                                            <div className="bg-orange over">
                                                <p className="it-accounts-for-over">Only 5-8% of grocery stores are organized corporations. The vast majority are “mom & pop” type shops we call “kiranas”</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">

                                        <figure>
                                            <img src={require('./image/chart.png')} className="carts-img" />
                                        </figure>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12 text-center padding-section">
                                        <p className="the-local-kirana-is">The local kirana is still the epicenter of Indian grocery business with 90% of distribution under its belt</p>
                                        <p className="the-local-kirana-is ">Online grocery is expected to be the convenience channel due to the increased stress requiring focus <br />on work life balances and also freeing time for family or leisure activities in this digital age</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-bg">
                            <div className="container">
                                <h2 class="heding-title">Why GG Grange?</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                        <figure>
                                            <img src={require('./image/why_gg_grange.png')} width="60%" />
                                        </figure>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                        <li className="store-menu">Blend of <b>Kirana</b> and <b>Online Grocery Shopping</b></li>
                                        <li className="store-menu">Serving through <b>Physical Outlet</b> as well as <b>Online Store</b></li>
                                        <li className="store-menu">Exclusive integration of <b>UPOS</b> store with <b>Grocery Store</b></li>
                                        <li className="store-menu">Unique <b>Cashback</b> options</li>
                                        <li className="store-menu">Opportunity for <b>GG Retailers</b> and <b>Consumers</b> to earn up to <b>₹1 crore</b></li>
                                        <li className="store-menu">One and only kirana store with <b>20 crore + product</b> and <b>services</b> with no investment in inventory</li>
                                        <li className="store-menu"><b>Low setup</b> cost</li>
                                        <li className="store-menu">Factors like increasing <b>Internet Penetration, Smartphone Users, Online shoppers, Logistics Favoring Online Grocery Shop</b></li>
                                    </div>
                                </div>
                                <h4 className="upos-cell">You are not alone in your sales, our other UPOS will support you to sell more.</h4>
                            </div>
                        </section>
                        <section className="section-bg">
                            <div className="container">
                                <h2 className="heding-title">Process Flow</h2>
                                <figure>
                                    <img src={require('./image/flow.png')} />
                                </figure>
                            </div>
                        </section>


                        <section className="section-bg">
                            <div className="container">
                                <h2 className="heding-title">Stockist/ACP</h2>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-12 text-center top-space">
                                        <h2 className="stockist"><span>Franchise Fee:</span> ₹1 lakh + GST <br /> (Margin: 1% to 2%)</h2>
                                        <h5 className="maintained">(Minimum ₹5 lakh Stock has to be maintained)</h5>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12 text-center">
                                        <figure>
                                            <img src={require('./image/stockist.png')} />
                                        </figure>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                                        <figure>
                                            <img src={require('./image/Stockist-1.png')} className="cart-img" />

                                        </figure>
                                        <h4 className="case-back">50% off on Franchise fee for ACP who has his own UPOS <br /> 100% off on Franchise Fee for ACP who is Share Holder of GG</h4>
                                        <h5 className="franchise-will-be-op"> Franchise will be operated on FOCO Model</h5>
                                        <p className="franchise-owned-com">(Franchise Owned Company Operated)</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section-bg">
                            <div className="container">
                                <h2 className="heding-title">Retailer</h2>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-12 text-center">
                                        <figure>
                                            <img src={require('./image/retailer.png')} />
                                        </figure>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12 text-center top-space">
                                        <h2 className="stockist"><span>Franchise Fee:</span> 1 lakh + GST (Margin : Average 10% - 15% + GG Coins + UPOS income)</h2>
                                        <h3 className="stock-hardware-se">(Stock + Hardware set up cost ₹1 lakh + GST)</h3>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                                        <figure>
                                            <img src={require('./image/flow-chart.png')} className="cart-img" />

                                        </figure>
                                        <h4 className="case-back">50% off on Franchise Fee for ACP who has his own UPOS <br /> 100% off on Franchise Fee for ACP who is Share Holder of GG</h4>
                                        <h5 className="franchise-will-be-op">Franchise will be operated on FOFO Model</h5>
                                        <p className="franchise-owned-com">(Franchisee-Owned Franchisee Operated)</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section-bg">
                            <div className="container">
                                <h2 className="heding-title">G3 Store (Global Garner Grange store)</h2>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-12 text-center top-space">
                                        <h2 className="stockist"><span>Franchise Fee:</span> ₹5 lakh + GST (Margin : Average 10% - 15% + GG Coins + UPOS income)</h2>
                                        <h3 className="stock-hardware-se font-weight-600">(₹25 lakh for Stock, Infrastructure &amp; Setup cost)</h3>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12 text-center">
                                        <figure>
                                            <img src={require('./image/Grange-Banner-Image.png')} />

                                        </figure>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                                        <figure>
                                            <img src={require('./image/g-3store.png')} className="cart-img" />
                                        </figure>
                                        <h4 className="case-back pt-5">50% off on Franchise fee for ACP who has his own UPOS <br /> 100% off on Franchise fee for ACP who is Share Holder of GG</h4>
                                        <h5 className="franchise-will-be-op">Franchise will be operated on FOFO Model</h5>
                                        <p className="franchise-owned-com">(Franchisee-Owned Franchisee Operated)</p>
                                    </div>
                                </div>
                            </div>
                        </section>


                        <section className="section-bg">
                            <div className="container">
                                <div className="stock-title mb-5">
                                    <h2>Benefits for Users</h2>
                                    <p style={{ color: '#212121' }}>Give Cashback/Reward to all your customers with Global Garner real cashback which can be transferred to bank account</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12 mb-5">
                                        <div className="ueser-bg-title">
                                            <figure>
                                                <img src={require('./image/my_circle.png')} className="mb-5" />
                                            </figure>
                                            <p className="my-circle">My Circle</p>
                                            <div className="tagged-title mb-5">
                                                <p><b>Just refer your friends & earn upto ₹1 crore as real GG Money</b></p>
                                                <p>UPVs will be tagged within a circle formed by a user by generating his UPV at the beginning, thereafter referring only 6 UPVs , thus creating his own circle.</p>
                                            </div>
                                            <a href={Config.MAIN_APP_URL + 'systematic-cashback'} target="_blank" rel="noopener noreferrer" className="view-more-btn">VIEW MORE</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12 mb-5">
                                        <div className="ueser-bg-title">
                                            <figure>
                                                <img src={require('./image/instant_cashback.png')} className="mb-5" />
                                            </figure>
                                            <p className="my-circle">(IC) Instant Cashback/Reward</p>
                                            <div className="tagged-title mb-5">
                                                <p><b>100% GG Coins on every purchase</b></p>
                                                <p>It is a cashback/reward in which user can shop and get Guaranteed 2% to 10% instant Cashback/Reward without waiting to form UPV or without new UPVs tagging under the purchase.</p>
                                            </div>
                                            <a href={Config.MAIN_APP_URL + 'systematic-cashback'} target="_blank" rel="noopener noreferrer" className="view-more-btn">VIEW MORE</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12 mb-5">
                                        <div className="ueser-bg-title">
                                            <figure>
                                                <img src={require('./image/rrp.png')} className="mb-5" />
                                            </figure>
                                            <p className="my-circle">(SCB RPP) Random Pick Process</p>
                                            <div className="tagged-title mb-5">
                                                <p><b>Get instant ₹10,000/- GG Coins</b></p>
                                                <p>In this, the system will randomly pick the user for any given slab/amount of cashback but there will be a guarantee of everyone getting the cashback/reward from a minimum 2% to a maximum 100% in a month.</p>
                                            </div>
                                            <a href={Config.MAIN_APP_URL + 'systematic-cashback'} target="_blank" rel="noopener noreferrer" className="view-more-btn">VIEW MORE</a>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12 mb-5">
                                        <div className="ueser-bg-title">
                                            <figure>
                                                <img src={require('./image/scb.png')} className="mb-5" />
                                            </figure>
                                            <p className="my-circle">(SCB) First In First Out 100% Cashback</p>
                                            <div className="tagged-title mb-5">
                                                <p>Get instant ₹10,000/- GG Coins</p>
                                                <p>It is a systematic cashback process in which cashback/reward is given in 3 unequal installments of ₹500/-, ₹1000/-, ₹8,500/- each as per a unique auto-tagging system, where the user has to do nothing except for generating his own UPVs. Also, get an additional ₹10,000/- by GG coin conversion as a fourth payout.</p>
                                            </div>
                                            <a href={Config.MAIN_APP_URL + 'systematic-cashback'} target="_blank" rel="noopener noreferrer" className="view-more-btn">VIEW MORE</a>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-xs-12 mt-5">
                                        <h2 className="also-give-e-commerc">Also, give E-Commerce Gift voucher and engage new users in your area. (promotional offer)</h2>
                                        <p className="note-get-instant-g">Note:- Get instant GG Coins on every approved purchase 2% to 100% Cashback/Reward through various cashback options</p>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12 mb-5 mt-5">
                                        <h2 className="gg-coins-on-every-pu">GG Coins on Every Purchase</h2>
                                        <p className="you-get-100-gg-coin">You get 100% GG Coins on every purchase. This can be redeemed with new purchases to the extent of minimum 5% of the bill amount and GG-Coins are also converted to GG Money as an additional 4th payout of SCB FIFO and in My Circle Cashback at different levels and the user can encash it to his Bank account.</p>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12 mb-5 mt-5">
                                        <figure>
                                            <img src={require('./image/gg-coins.png')} width="40%" />
                                        </figure>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-xs-12 mt-5">
                                        <h2 className="best-items-at-best-p">Best Items at Best Prices</h2>
                                        <div className="row">
                                            <div className="col-sm-4 col-md-4 col-6 text-center">
                                                <figure>
                                                    <img src={require('./image/online-catalogue.png')} />
                                                </figure>
                                                <p className="font-weight-bold">Online Catalogue</p>
                                            </div>
                                            <div className="col-sm-4 col-md-4 col-6 text-center">
                                                <figure>
                                                    <img src={require('./image/cart.png')} />
                                                </figure>
                                                <p className="font-weight-bold">Option of Online <br /> Grocery shopping</p>
                                            </div>
                                            <div className="col-sm-4 col-md-4 col-6 text-center">
                                                <figure>
                                                    <img src={require('./image/doorstep-delivery.png')} />
                                                </figure>
                                                <p className="font-weight-bold">Doorstep Delivery</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer></Footer>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}