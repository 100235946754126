import React from 'react';
import TopBar from "../core/components/TopBar";
import ACP from "./Component/ACP";

export default class Mobile extends React.Component {
    componentDidMount() {
        if(process.env.REACT_APP_ENV==='production'){
            var meta = document.createElement('meta');
            var meta1 = document.createElement('meta');
            meta.name = "description";
            meta.content = "ACP is going to be the Exclusive Head of the particular pincode of Global Garner Business.";
            meta1.name = 'keywords';
            meta1.content = "Assistant Channel Partner";
            meta1.contest = "Assistant Channel Partner";
            document.getElementsByTagName('head')[0].appendChild(meta);
            document.getElementsByTagName('head')[0].appendChild(meta1);
        }
    }
    render(){
        return(
            <div className="container mb-5">
				<TopBar title="Login & Register"/>
				<div className="min-height">
					<ACP/>
					<div className="page-content-right"></div>
				</div>
            </div>
        );
    }
}