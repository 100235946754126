import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Auth } from "gg-react-utilities";
import { aquashInquiry } from "../../core/actions/index"
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { Toaster, Position, Intent } from "@blueprintjs/core";

const options = [
    {
        label: "TAMIL NADU, PONDICHERRY",
        value: "TAMIL NADU, PONDICHERRY",
    },
    {
        label: "PUNJAB, HARYANA, CHANDIGARH, DELHI, JAMMU & KASHMIR, HIMANCHAL PRADESH , UTTARAKHAND",
        value: "PUNJAB, HARYANA, CHANDIGARH, DELHI, JAMMU & KASHMIR, HIMANCHAL PRADESH , UTTARAKHAND",
    },
    {
        label: "ANDHRA PRADESH, TELANGANA",
        value: "ANDHRA PRADESH, TELANGANA",
    },
    {
        label: "UTTAR PRADESH",
        value: "UTTAR PRADESH",
    },
    {
        label: "MAHARASHTRA, GOA",
        value: "MAHARASHTRA, GOA",
    },
    {
        label: "KARNATAKA",
        value: "KARNATAKA",
    },
    {
        label: "KERALA",
        value: "KERALA",
    },
    {
        label: "BIHAR, JHARKHAND",
        value: "BIHAR, JHARKHAND",
    },
    {
        label: "WEST BENGAL",
        value: "WEST BENGAL",
    },
    {
        label: "DISHA CHATTISGARH",
        value: "DISHA CHATTISGARH",
    },
    {
        label: "GUJARAT, DAMAN & DIU",
        value: "GUJARAT, DAMAN & DIU",
    },
    {
        label: "RAJASTHAN",
        value: "RAJASTHAN",
    },
    {
        label: "NORTH EAST : Assam, Arunachal, Manipur, Meghalaya, Mizoram, Nagaland, Sikkim, Tripura,MADHYA PRADESH",
        value: "NORTH EAST : Assam, Arunachal, Manipur, Meghalaya, Mizoram, Nagaland, Sikkim, Tripura,MADHYA PRADESH",
    },
];

const operation = [
    {
        label: "Aquash Trolley (Minimum 25 Franchise Required for a City )",
        value: "Aquash Trolley (Minimum 25 Franchise Required for a City )",
    },
    {
        label: "Aquash Pincode (Minimum 1 Required for a Pincode )",
        value: "Aquash Pincode (Minimum 1 Required for a Pincode )",
    },

]
const Frenchies = [
    "Aquash Trolley Rs 4.5 Lakh (Setup Cost) + GST",
    "Aquash Pincode Stockist Rs 5 Lakh Inventory + 1 Lakh FF + GST",
    "Aquash City , State Franchise (Depending upon Population Approx 1 Crore to 10 Crore) + GST",
];
const Condition = [
    "Booking Amount 10% of (Stock Value + FF)",
    "Booking is not a guarantee of Franchise Allotment",
    "By 2nd October 2020 we will declare the Allotment of Franchise",
    "All those who will not be allotted the franchise the booking amount will be refunded \n by 5th October 2020",
    "If Franchise is alloted and the person is not depositing the remaining amount a  per the instruction by the company than booking amount will be forfeited",
    "Detailed agreement will be shared post Allotment of franchise",
    "Other Details as per the Brochures",
]
export default class Enquire extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: '',
            email: null,
            name: null,
            Partner: null,
            mobile: null,
            Pincode: null,
            State: null,
            city: null,
            Terms: [],
            start_operation_number: null,
            Amount: null,
            hasEmail: null,
            hasName: null,
            hasPartner: null,
            hasMobile: null,
            hasPincode: null,
            hasState: null,
            hasCity: null,
            hasTerms: null,
            hasStart_operation_number: null,
            hasAmount: null,
            hasFrenchies: false,
            selectedOption: false,
            selfDeclaration: false,
            hasFranchise: false,
            frenchies: [],
            isLoading:false,
            isLoggedIn:false
        };
    }

    componentWillReceiveProps(nextProps) {

        this.checkAuth()

        if (this.state.isLoggedIn) {
            this.setState({show: nextProps.show})
        }
    }

    async checkAuth() {

        Auth.isLoggedInAsync().then((res) => {
            this.setState({ isLoggedIn: res }, () => {
                if (!res) {
                    Auth.login(true);
                }
            })
        });
    }

    handleClose = () => {
        this.setState({
            show: false,
            email: null,
            name: null,
            Partner: null,
            mobile: null,
            Pincode: null,
            State: null,
            city: null,
            Terms: [],
            start_operation_number: null,
            Amount: null,
            hasEmail: null,
            hasName: null,
            hasPartner: null,
            hasMobile: null,
            hasPincode: null,
            hasState: null,
            hasCity: null,
            hasTerms: null,
            hasStart_operation_number: null,
            hasAmount: null,
            hasFrenchies: false,
            selectedOption: false,
            selfDeclaration: false,
            hasFranchise: false,
            frenchies: [],
            isLoading:false
        })
    }
    handlesubmit = () => {
        if (this.state.State === "Select your State") {this.setState({ State: null })}
        if(this.state.start_operation_number==="Select your Requirement"){this.setState({ start_operation_number: null })}
        if(this.state.frenchies.length===0){this.setState({hasFranchise:true})}else{this.setState({hasFranchise:false})}
        if (this.state.selectedOption === true) { this.setState({ selfDeclaration: false }) }else { this.setState({ selfDeclaration: true }) }
        if (this.state.Terms.length === 7) { this.setState({ hasTerms: false }) } else {  this.setState({ hasTerms: "select All Terms & Conditions" }) }
        if(this.state.Partner === null){this.setState({hasPartner:"Partner Code is required"})}else{this.setState({hasPartner:false})}
        if(this.state.name === null){this.setState({hasName:"Name is required"})}else{this.setState({hasName:false})}
        if(this.state.mobile === null){this.setState({hasMobile:"Mobile Number is required"})}else{this.setState({hasMobile:false})}
        if(this.state.email === null){this.setState({hasEmail:"Email is required"})}else{this.setState({hasEmail:false})}
        if(this.state.city === null){this.setState({hasCity:"City is required"})}else{this.setState({hasCity:false})}
        if(this.state.Pincode === null){this.setState({hasPincode:"Pincode is required"})}else{this.setState({hasPincode:false})}
        if(this.state.Amount === null){this.setState({hasAmount:"Amount is required"})}else{this.setState({hasAmount:false})}       
        let data = {
            "acp_partner_code": this.state.Partner,
            "user_name": this.state.name,
            "mobile": this.state.mobile,
            "email": this.state.email,
            "state": this.state.State,
            "city": this.state.city,
            "pincode": this.state.Pincode,
            "start_operation_number": this.state.start_operation_number,
            "terms_condition": this.state.Terms,
            "franchise": this.state.frenchies,
            "booking_amount": this.state.Amount
        }
        if (this.state.selectedOption === true && this.state.frenchies.length !=0 && this.state.Terms.length === 7 &&this.state.Partner != null&&this.state.name != null&&this.state.mobile != null&&this.state.email != null&&this.state.city != null&&this.state.Pincode != null&&this.state.Amount != null) {
            this.setState({ isLoading:true })    
            aquashInquiry(data).then(res => {
                this.setState({ selfDeclaration: false })            
                this.handleClose()
                Toaster.create({ position: Position.TOP }).show({ message: res.data.message, intent: Intent.SUCCESS });
              
            }).catch(error => {
              Toaster.create({ position: Position.TOP_RIGHT }).show({ message: "Invalid Data", intent: Intent.DANGER });
            
                let value
                Object.values(error).map(err => { value = err.data })
                let data = Object.values(value)
                this.setState({
                    isLoading:false,
                    hasEmail: data[3].email === undefined ? "" : data[3].email[0],
                    hasName: data[3].user_name === undefined ? "" : data[3].user_name[0],
                    hasPartner: data[3].acp_partner_code === undefined ? "" : data[3].acp_partner_code[0],
                    hasMobile: data[3].mobile === undefined ? "" : data[3].mobile[0],
                    hasPincode: data[3].pincode === undefined ? "" : data[3].pincode[0],
                    hasState: data[3].state === undefined ? "" : data[3].state[0],
                    hasCity: data[3].city === undefined ? "" : data[3].city[0],
                    hasTerms: data[3].terms_condition === undefined ? "" : data[3].terms_condition[0],
                    hasStart_operation_number: data[3].start_operation_number === undefined ? "" : data[3].start_operation_number[0],
                    hasAmount: data[3].booking_amount === undefined ? "" : data[3].booking_amount[0],
                    hasFrenchies: data[3].franchise === undefined ? "" : data[3].franchise[0]
                })
            })
        }
    }
    StatehandleChange = (e) => { this.setState({ State: e.target.value }); }
    operationHandleChange = (e) => { this.setState({ start_operation_number: e.target.value }); }
    handleFrenchies = (selected) => { this.setState({ frenchies: selected }) }
    handleTerms = (selected) => { this.setState({ Terms: selected }) }

    render() {
        console.log(this.state.show)
        return (
            <Modal className="aquash"
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                aria-labelledby="ModalHeader"
                size="lg"
            >
                <div className="main-model">
                    <div className="model-background">
                        <p className="modal-form">Form</p>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter" className="modal-title">Aquash Booking Form</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form>
                                <div class="form-group">
                                    <label className="booking-label">Email address<span>*</span></label>
                                    <input type="email" placeholder="Your email" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }); }} className={(this.state.hasEmail) ? 'form-control booking-form border-danger' : 'form-control booking-form'} required />
                                    <span className="text-danger booking-label">{this.state.hasEmail}</span>
                                </div>
                                <div class="form-group">
                                    <label className="booking-label">Name<span>*</span></label>
                                    <input type="text" placeholder="Your Name" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }); }} className={(this.state.hasName) ? 'form-control booking-form border-danger' : 'form-control booking-form'} required />
                                    <span className="text-danger booking-label">{this.state.hasName}</span>
                                </div>
                                <div class="form-group">
                                    <label className="booking-label">ACP / Partner Code<span>*</span></label>
                                    <input type="text" placeholder="Your Code" value={this.state.Partner}
                                        onChange={(e) => { this.setState({ Partner: e.target.value }); }} required className={(this.state.hasPartner) ? 'form-control booking-form border-danger' : 'form-control booking-form'} />
                                    <span className="text-danger booking-label">{this.state.hasPartner}</span>
                                </div>
                                <div class="form-group">
                                    <label className="booking-label">Mobile Number<span>*</span></label>
                                    <input type="number" placeholder="Mobile Number" value={this.state.mobile}
                                        onChange={(e) => { this.setState({ mobile: e.target.value }); }} required className={(this.state.hasMobile) ? 'form-control booking-form border-danger' : 'form-control booking-form'} />
                                    <span className="text-danger booking-label">{this.state.hasMobile}</span>
                                </div>
                                <div class="form-group">
                                    <label className="booking-label">City<span>*</span></label>
                                    <input type="text" placeholder="Enter City Name" value={this.state.city}
                                        onChange={(e) => { this.setState({ city: e.target.value }); }} required className={(this.state.hasCity) ? 'form-control booking-form border-danger' : 'form-control booking-form'} />
                                    <span className="text-danger booking-label">{this.state.hasCity}</span>
                                </div>
                                <div class="form-group">
                                    <label className="booking-label">Pincode<span>*</span></label>
                                    <input type="number" placeholder="Enter your Pincode" value={this.state.Pincode}
                                        onChange={(e) => {
                                            this.setState({ Pincode: e.target.value });
                                        }} className={(this.state.hasPincode) ? 'form-control booking-form border-danger' : 'form-control booking-form'} />
                                    <span className="text-danger booking-label">{this.state.hasPincode}</span>
                                </div>
                                <div class="form-group form-select">
                                    <label className="booking-label">Select State<span>*</span></label>
                                    <div className="position-relative">
                                        <i class="fas fa-chevron-down"></i>
                                        <select
                                            value={this.state.State}
                                            onChange={this.StatehandleChange}
                                            required className={(this.state.hasState) ? 'form-control booking-form border-danger pr-4 text-chnage' : 'form-control booking-form pr-4 text-chnage'}>
                                            <option selected={(this.state.State === undefined) ? 'selected' : ''}>Select your State </option>
                                            {options.map((option) => (<option value={option.value}>{option.label}</option>
                                            ))}  </select></div>
                                    <span className="text-danger booking-label">{this.state.hasState}</span>
                                </div>
                                <div class="form-group form-select">
                                    <label className="booking-label">Select Franchise (You can Select Multiple franchise)<span>*</span></label>
                                    <div className="position-relative">
                                        <i class="fas fa-chevron-down"></i>
                                        <DropdownMultiselect
                                            placeholder="Select Franchise"
                                            handleOnChange={this.handleFrenchies}
                                            options={Frenchies}
                                            className={(this.state.hasFranchise) ? 'form-control booking-form border-danger' : 'form-control booking-form'}
                                            name="countries"
                                        />{this.state.hasFranchise === true && <span className="text-danger booking-label">please select franchise</span>}
                                    </div>
                                </div>
                                <div class="form-group form-select">
                                    <label className="booking-label">Please Note Minimum number required to start Operations<span>*</span></label>
                                    <div className="position-relative">
                                        <i class="fas fa-chevron-down"></i>
                                        <select
                                            onChange={this.operationHandleChange}
                                            className={(this.state.hasStart_operation_number) ? 'form-control booking-form border-danger' : 'form-control booking-form'}>
                                            <option selected={(this.state.start_operation_number === undefined) ? 'selected' : ''}>Select your Requirement </option>
                                            {operation.map((option) => (<option value={option.value}>{option.label}</option>))}
                                        </select></div>
                                    <span className="text-danger booking-label">{this.state.hasStart_operation_number}</span>
                                </div>
                                <div class="form-group form-select">
                                    <label className="booking-label">Pre Booking Terms & Conditions (Tick all Boxes)<span>*</span></label>
                                    <div className="position-relative">
                                        <i class="fas fa-chevron-down"></i>
                                        <DropdownMultiselect
                                            placeholder="Select Details"
                                            handleOnChange={this.handleTerms}
                                            options={Condition}
                                            name="countries"
                                        />{this.state.hasTerms === true && <span className="text-danger booking-label">Please Select Pre Booking Terms & Conditions</span>}
                                    </div>
                                    <span className="text-danger booking-label">{this.state.hasTerms}</span>
                                </div>
                                <div class="form-group form-select">
                                    <label className="booking-label">Self Declaration<span>*</span></label>
                                    <p className="booking-policy"><input type="checkbox" className={(this.state.selfDeclaration === true) ? 'border-danger' : ''} checked={this.state.selectedOption === true ? "checked" : ""} onChange={() => {
                                        this.setState({ selectedOption: !this.state.selectedOption })
                                    }} />
                                        I Hereby Declare that i have understood above said terms & condition and willing to book Franchise as given above</p>
                                    {this.state.selfDeclaration === true && <span className="text-danger booking-label">Please Select Terms & Condition</span>}
                                </div>
                                <div class="form-group">
                                    <label className="booking-label">Booking Amount Detail (UTR No / Ref No)<span>*</span></label>
                                    <input type="number" placeholder="Select Amount Detail" value={this.state.Amount}
                                        onChange={(e) => { this.setState({ Amount: e.target.value }); }} required className={(this.state.hasAmount) ? 'form-control booking-form border-danger' : 'form-control booking-form'} />
                                    <span className="text-danger booking-label">{this.state.hasAmount}</span>
                                </div>
                            </form> </Modal.Body>
                        <Modal.Footer>
                            {this.state.isLoading ===true ?
                        <div className="aquash-loader">
                           </div>:
                          <React.Fragment>
                           <Button className="cancel-btn" onClick={this.handleClose}>Cancel</Button>
                            <Button className="submit-btn " onClick={this.handlesubmit}>Submit</Button>
                            </React.Fragment>}
                        </Modal.Footer>
                    </div>
                </div>
            </Modal>
        );
    }
}

