import React from 'react';

import  '../../../src/core/styles/_grange.scss';
export default class Business extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      nav:false
    };
  }
   // handleClick = () => {
    //   this.setState({ show: !this.state.show })
    // }
    mobileNav =()=>{
      this.setState({nav:!this.state.nav})
    }
  render() {

   
    return (
      <React.Fragment>

        <header>
          <nav className="navbar navbar-expand-md grange-header fixed-top ">
            <div className="container">
              <a className="navbar-brand" href="#">
                <img src={require('../image/grange_logo_header.png')} />
              </a>
               <button className="navbar-toggler grange-bar-ico" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
               <i className="fas fa-bars" onClick={this.mobileNav}/> 
              </button>  
              <div className={this.state.nav===true?"collapse navbar-collapse show":"collapse navbar-collapse"} id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                  {/* <li className="nav-item active">
                    <a className="nav-link nav-grange-link" href="#">Contact Support</a>
                  </li> */}
                  <li className="nav-item">
                  <a className="nav-link nav-grange-link" download="GG_Grange.pdf" href={require("../brochure/GG_grange_brochure.pdf")}   >Download Brochure</a>
                 </li>
                  {/* <li className="nav-item">
                    <a className="nav-link nav-grange-link" href="#">Grange Tutorial</a>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link nav-grange-link dropdown-toggle login-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Login
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a className="dropdown-item" href="#">Login as Trolley Manager</a>
                      <a className="dropdown-item" href="#">Login as Trolley Manager</a>
                      <a className="dropdown-item" href="#">Login as Trolley Manager</a>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </header>

      </React.Fragment>
    )
  }
}
