import React from 'react';
import TopBar from "../core/components/TopBar";
import MCP from "./Component/MCP";

export default class Mobile extends React.Component {
    componentDidMount() {
        if(process.env.REACT_APP_ENV==='production'){
            var meta = document.createElement('meta');
            var meta1 = document.createElement('meta');
            meta.name = "description";
            meta.content = "MCP is going to be Non - Exclusive Head of the sale of Global Garner business in State.";
            meta1.name = 'keywords';
            meta1.content = "Master Channel Partner";
            meta1.contest = "Master Channel Partner";
            document.getElementsByTagName('head')[0].appendChild(meta);
            document.getElementsByTagName('head')[0].appendChild(meta1);
        }
    }
    render(){
        return(
            <div className="container mb-5">
				<TopBar title="Login & Register"/>
				<div className="min-height">
					<MCP/>
					<div className="page-content-right"></div>
				</div>
            </div>
        );
    }
}