import React from 'react';
import { Link } from "react-router-dom";
import Config from "../../Config";
import { style } from '../../core/styles/_sc.scss'
import MultiCarousel from './ReactMultiCarousel';
import Enterprenuer_group from "../../core/images/png/people_group.png";
import mission from "../../core/images/JPG/mission.jpg";
import vision from "../../core/images/JPG/vision.jpg";

export default class Suxes_Consultancy extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid ban_pos p-0">

                    {/* Banner Start */}
                    <div className='container-fluid'>
                        <div className="row">
                            <div className='col-12'>
                                <MultiCarousel />
                            </div>
                        </div>
                    </div>
                    {/* Banner End */}  

                    {/* People Group Start */}
                    <div className='container'>
                        <div className="row align-item-center">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="banner_content">
                                    <img src={Enterprenuer_group} />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12">
                                <div className="banner_content">
                                    <h3>SUXES is ONE STOP solution provider and problem Solver for all your business, financial and Legal Advisory needs</h3>
                                    <p>SuxesNet is the state-wide Sales Distribution Network of Social Entrepreneurs to sell products & services of MSME & Start-ups. SuxesNet provides you a digital mall to sell Products & Services with PoS or microATM or Android apps. As a social entrepreneur, with one time investment, you will earn lifetime income forever, no age-barrier, no education-qualification…</p>
                                    <p>You can earn commission by on-boarding local vendors, manufactures, you can recruit your friends & create more entrepreneurs. To become successful join SuxesNet's Social Entrepreneur Network. SUXES will train you & provide 24x7 support.</p>
                                    <p>Our SUXES Mantra: <b>Come Together, Work Together, Learn Together, Earn Together & Grow Together.</b></p>

                                    <Link to={"/suxesnet"} className="btn custom_btn">
                                        Log In / Sign Up
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* People Group End */}

                    {/* Vision Mission Start */}
                    <section className='ptb-60 custome-bg mt-5'>
                        <div className='container'>
                            <div className='row d-flex justify-content-center m-0'>
                                <div className="col-lg-12 p-0">
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12 col-sm-12 MV d-flex align-items-stretch'>
                                            <div className='MV_box'>
                                                <div className='MV_box_img'>
                                                    <img className='vision' src={vision} alt="Vision" />
                                                </div>
                                                <div className='MV_box_content'>
                                                    <h3>Vision</h3>
                                                    <p>Promote nation building through team, collective & Collaborative success by creating an ECOSYSTEM for Rural Development, Women and Youth empowerment,MSME Empowerment & Social Entrepreneurship.</p>
                                                    <p>Design, build processes that would make your business more enjoyable & profitable by providing solutions to your non-core functions.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12 col-sm-12 MV d-flex align-items-stretch'>
                                            <div className='MV_box'>
                                                <div className='MV_box_img'>
                                                    <img className='mission' src={mission} alt="Mission" />
                                                </div>
                                                <div className='MV_box_content'>
                                                    <h3>Mission</h3>
                                                    <p>To cause transformation by redefining systems, processes, values to enrich people's lives</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Vision Mission End */}

                    {/* Ceenter para Section Start */}
                    <div className="container mt-2">
                        <div className="row">
                            <div className="content">
                                <h3>Freedom to work in the area of your choice & liking. We promote and sell products and services
                                    through our Digital Mall in Health & Wellness, Education, Agriculture, Finance, FMCG sectors.</h3>
                                <p>For details, please contact us.</p>
                                <p>In addition, Suxes Consultancy provides all support services for MSME entrepreneurs, start-ups to
                                    make their business profitable & sustainable by taking care of non-core functions. We redefine
                                    success by creating strategies, systems & processes.</p>
                            </div>
                        </div>
                    </div>
                    {/* Ceenter para Section End */}

                    {/* Suxes Management Team Start */}
                    <section className="mb-5 custome-bg2 mt_170">
                        <div className="container-fluid">
                            <div className='SUXEX_team'>
                                <div className="our_team">
                                    <div className="row d-flex justify-content-center m-0'">
                                        <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 text-center">
                                            <div className="our_team_header">
                                                <h4>Suxes Management Team</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container'>
                                        <div className='row Team_bg_box'>
                                            <div className="col-lg-4">
                                                <div className="our_team_card">
                                                    <h4>Subhash Tanwar</h4>
                                                    <p>Founder & CEO (Professional telecom engineer from reputed engineering institute), Hard-core Sales Expert, Rich corporate experience at CEO level in multinational companies in India & Africa, Extensive Political & Social Network, Business Mentor.</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="our_team_card">
                                                    <h4>Unmesh Bagwe</h4>
                                                    <p>Co-Founder & COO (Professional telecom engineer from reputed engineering institute) Hard core Techy Project Manager (ex-PMP), Top-level corporate career in India & Europe, Corporate Trainer, Long term association with progressive social movement.</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="our_team_card our_team_card3">
                                                    <h4>Premendra Kashid</h4>
                                                    <p>Head of Sales and Business Development (MBA-Fin & Mktg) with 25+ yrs experience (BFSI Sector/ StanChart, Max Life, Tata AIG, Birla Sunlife, Kotak Life) as Business Development, Operations & Strategy, Business mentor (Sales & Strategy)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Suxes Management Team Start */}
                </div>
            </React.Fragment >
        );
    }
}