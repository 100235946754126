import React from 'react'
import { Route } from 'react-router-dom'
// import Header from './components/Header';
import HeaderFooter from "./HeaderFooter";


const PrivateRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={(props) => (
        <HeaderFooter>
          <div className="business">
            <Component {...props} />
          </div>
        </HeaderFooter>
    )}/>
);

export default PrivateRoute;
