import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Banner_1 from "../../core/images/png/banner-1.png";
import Banner_2 from "../../core/images/png/banner-2.png";
import Banner_3 from "../../core/images/png/banner-3.png";

function MultiCarousel() {


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <React.Fragment>
            <Carousel
                className='CarouselSlider'
                responsive={responsive}
                arrows={false}
                showDots={true}
                autoPlaySpeed={4000}
                autoPlay={true}
                infinite={true}
            >
                <div>
                    <img className="w-100 pb-3" src={Banner_1} />
                </div>
                <div>
                    <img className="w-100 pb-3" src={Banner_2} />
                </div>
                <div>
                    <img className="w-100 pb-3" src={Banner_3} />
                </div>
            </Carousel>
        </React.Fragment>
    )
}

export default MultiCarousel